import {
  getAttributes,
  getAttribute,
  addAttribute,
  editAttribute,
  deleteAttribute,
  addSubattribute,
  editSubattribute,
  deleteSubattribute
} from '@/services/AttributesService'

const ID_FUNC = x => x

export default {
  namespaced: true,

  state: {
    attributes: [],
    currentPage: 1,
    total: 0,
    count: 0,
    attribute: null,
    subattributeLoading: false,
    loading: false,
    error: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    }
  },

  actions: {
    getAttributes({ commit }, { page = 1, filters = {} }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      getAttributes({ page, filters })
        .then(
          response => {
            const { attributes, totalCount, count } = response.data
            commit('SET_STATE_VALUE', { field: 'error', value: null })
            commit('SET_STATE_VALUE', { field: 'attributes', value: attributes })
            commit('SET_STATE_VALUE', { field: 'total', value: totalCount })
            commit('SET_STATE_VALUE', { field: 'count', value: count })
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    getAttribute({ commit }, { id }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getAttribute(id).then(response => {
        commit('SET_STATE_VALUE', {
          field: 'attribute',
          value: response.data
        })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      })
    },
    addAttribute({ commit }, { name, subattributes, families, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      addAttribute({ name, families, subattributes })
        .then(
          () => onFinish(true, 'addAttribute'),
          () => onFinish(false, 'addAttribute')
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    editAttribute({ commit }, { name, families, id, wepod, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      editAttribute({ name, families, id, wepod })
        .then(
          () => onFinish(true, 'editAttribute'),
          () => onFinish(false, 'editAttribute')
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    deleteAttribute({ commit }, { id, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      deleteAttribute(id)
        .then(
          () => {
            onFinish(true, 'deleteAttribute')
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
            onFinish(false, 'deleteAttribute')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    addSubattribute({ commit }, { id, name, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'subattributeLoading', value: true })
      addSubattribute({ id, name })
        .then(
          () => {
            onFinish(true, 'addSubattribute')
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
            onFinish(false, 'addSubattribute')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'subattributeLoading', value: false }))
    },
    editSubattribute({ commit }, { id, name, subattributeId, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'subattributeLoading', value: true })
      editSubattribute({ id, name, subattributeId })
        .then(
          () => {
            onFinish(true, 'editSubattribute')
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
            onFinish(false, 'editSubattribute')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'subattributeLoading', value: false }))
    },
    deleteSubattribute({ commit }, { id, subattributeId, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'subattributeLoading', value: true })
      deleteSubattribute({ id, subattributeId })
        .then(
          () => {
            onFinish(true, 'deleteSubattribute')
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
            onFinish(false, 'deleteSubattribute')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'subattributeLoading', value: false }))
    }
  }
}
