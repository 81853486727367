export const defaultPrintingArea = {
  height: 100,
  width: 200,
  xCoordinate: 50,
  yCoordinate: 50,
  images: []
}

export const tabNamePrefix = { default: 'tab-', new: 'tab-new-' }

export const canvasAreaDefaultSize = 400

export const printingAreaOpacity = { area: 0.4, default: 1 }

export const disabledKeysTabEdit = {
  tagName: 'SPAN',
  keyEnter: 'Enter',
  keyBackspace: 'Backspace',
  keyArrowRight: 'ArrowRight',
  keyArrowLeft: 'ArrowLeft',
  keyArrowDown: 'ArrowDown',
  keyArrowUp: 'ArrowUp'
}

export const printingAreaActions = { add: 'add', edit: 'edit', delete: 'delete' }

export const minimumAreasQuantity = 1
