import Vue from 'vue'
import Vuelidate from 'vuelidate'
import lang from 'element-ui/lib/locale/lang/es'
import locale from 'element-ui/lib/locale'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import ElementUI, {
  Button,
  Card,
  Carousel,
  CarouselItem,
  Dialog,
  Divider,
  Dropdown,
  Form,
  FormItem,
  Image,
  Input,
  InputNumber,
  Menu,
  MenuItem,
  MenuItemGroup,
  Option,
  Pagination,
  Select,
  Switch,
  Submenu,
  Radio,
  RadioGroup,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Upload
} from 'element-ui'
import vuetify from './plugins/vuetify'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css'

import App from './App'

import store from './store'

import i18n from './config/i18n'
import { checkPermission } from './config/roles'
import router, { routes } from './config/routes'

Vue.config.productionTip = false

locale.use(lang)

Vue.use(Button)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(ElementUI)
Vue.use(Dialog)
Vue.use(Divider)
Vue.use(Dropdown)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Image)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Submenu)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(TabPane)
Vue.use(Upload)
Vue.use(Vuelidate)
Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: process.env.VUE_APP_APM_ELASTIC_SERVICE_NAME,
    secretToken: process.env.VUE_APP_APM_ELASTIC_SECRET_TOKEN,
    serverUrl: process.env.VUE_APP_APM_ELASTIC_SERVER_URL,
    environment: process.env.VUE_APP_NODE_ENV,
    distributedTracingOrigins: [process.env.VUE_APP_API_BASE_URL]
  }
})

router.beforeEach((to, from, next) => {
  const { currentUser, isLogged } = store.state.auth
  const { isPrivate, permission } = to.meta
  if (!isPrivate || (isPrivate && checkPermission(currentUser.permissions, permission))) next()
  else if (!isLogged) {
    store.dispatch('auth/isUserLoggedIn', to)
    next({ name: routes.login })
  }
})

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
