import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

import auth from './modules/auth'
import catalog from './modules/catalog'
import configuration from './modules/configuration'
import orders from './modules/orders'
import domains from './modules/domains'
import product from './modules/product'
import reservations from './modules/reservations'
import attributes from './modules/attributes'
import roles from './modules/roles'
import techniques from './modules/techniques'
import user from './modules/user'
import holidays from './modules/holidays'
import BOUsers from './modules/BOUsers'
import advances from './modules/advances'
import complaints from './modules/complaints'
import productFamilies from './modules/productFamilies'
import reports from './modules/reports'
import kit from './modules/kit'
import discounts from './modules/discounts'
import printingProfiles from './modules/printingProfiles'
import logisticOperators from './modules/logisticOperators'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    catalog,
    configuration,
    orders,
    domains,
    product,
    reservations,
    attributes,
    roles,
    techniques,
    user,
    holidays,
    BOUsers,
    advances,
    complaints,
    productFamilies,
    reports,
    kit,
    discounts,
    printingProfiles,
    logisticOperators
  },
  // plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'production'
})
