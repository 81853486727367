export default {
  title: 'Catálogos',
  catalogName: 'Nombre del catálogo',
  all: 'Todos',
  active: 'Habilitados',
  inactive: 'Deshabilitados',
  noResultMessage: 'No se encontraron catálogos',
  idColumn: 'id',
  nameColumn: 'Nombre',
  catalogUrlColumn: 'Archivo',
  enabledColumn: 'Habilitado',
  createCatalog: 'Crear catálogo',
  downloadButton: 'Descargar catálogo',
  error: {
    deleteCatalog: 'Hubo un error al borrar el catálogo',
    getCatalogs: 'Hubo un error al requerir los catálogos'
  },
  success: {
    deleteCatalog: 'El catálogo fue borrado'
  }
}
