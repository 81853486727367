import { getDomains, changeDomains } from '@/services/DomainsService'
import { isError } from '@/utils/api'

export default {
  namespaced: true,

  state: {
    loading: true,
    error: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    }
  },

  actions: {
    async getDomains({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const response = await getDomains({})
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        return []
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
      return response.data
    },
    // eslint-disable-next-line no-unused-vars
    async changeItem({ commit }, item) {
      await changeDomains(item)
    }
  }
}
