export default {
  cancelButtonText: 'No',
  confirmButtonText: 'Sí',
  confirmTitle: 'Confirmar',
  info: {
    title: 'Información'
  },
  error: {
    title: 'Error',
    message: 'Ocurrió un error al guardar los datos',
    usedWBUrl: 'La url seleccionada ya esta en uso'
  },
  success: {
    title: 'OK',
    message: 'Edición exitosa'
  }
}
