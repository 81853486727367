<template lang="pug">
  #app.row
    sidebar(v-if='isLogged')
    .main
      router-view
      .w-100.version
        h1
          | v{{ version }}
</template>

<script>
import { mapState } from 'vuex'

import Sidebar from './components/Sidebar'

import packageJson from '@/../package.json'

export default {
  components: { Sidebar },
  computed: {
    ...mapState({
      currentUser: state => state.auth.currentUser,
      isLogged: state => state.auth.isLogged
    }),
    version() {
      return packageJson.version
    }
  }
}
</script>

<style lang="scss">
@import './scss/application';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  justify-content: center;
  min-height: 100vh;
}

.main {
  flex: 1;
  position: relative;
}

.version {
  background-color: #f7f7f7;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
}
</style>
