import {
  getCatalogs,
  getCatalog,
  addCatalog,
  editCatalog,
  deleteCatalog,
  getAllCatalogsOrder
} from '@/services/CatalogsService'
import { uploadImage } from '@/services/ImageService'
import { uploadImageAWS } from '@/services/s3Service'

const ID_FUNC = x => x
const CONTENT_TYPE = 'Content-Type'

export default {
  namespaced: true,

  state: {
    catalogs: [],
    catalogsRange: [],
    currentPage: 1,
    total: 0,
    loading: false,
    catalog: null,
    error: null,
    count: 0
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_CATALOGS(state, { catalogs, totalCount, count }) {
      state.catalogs = catalogs
      state.total = totalCount
      state.count = count
    },
    SET_CATALOG(state, catalog) {
      state.catalog = catalog
    },
    EDIT_CATALOG_ORDER_VALUE(state, { newValue, oldValue, id }) {
      const index = state.catalogsRange.indexOf(newValue)
      state.catalogsRange.splice(index, 1)
      if (oldValue !== 0) state.catalogsRange.push(oldValue)
      state.catalogsRange = state.catalogsRange.sort((a, b) => a - b)
      state.catalogs.find(x => x.id === id).order = newValue
    }
  },

  actions: {
    getCatalogs({ commit }, { page = 1, filters = {}, onSuccess = ID_FUNC, onFailure = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      getCatalogs({ page, filters }).then(
        response => {
          const { catalogs, totalCount, count } = response.data
          commit('SET_STATE_VALUE', { field: 'error', value: null })
          commit('SET_CATALOGS', { catalogs, totalCount, count })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onSuccess('getCatalogs')
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onFailure('getCatalogs', status, statusText)
        }
      )
    },
    getCatalog({ commit }, { id, onSuccess = ID_FUNC, onFailure = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getCatalog(id).then(
        response => {
          const { catalog } = response.data
          commit('SET_STATE_VALUE', { field: 'error', value: null })
          commit('SET_CATALOG', catalog)
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onSuccess('getCatalog')
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { type: 'getCatalog', status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onFailure('getCatalog', status, statusText)
        }
      )
    },
    uploadCatalogFile({ commit }, file) {
      const fileNameSplit = file.name.split('.')
      const imageData = {
        extension: fileNameSplit.pop(),
        file_name: fileNameSplit.join(),
        path: '/catalogs'
      }
      return uploadImage(imageData).then(
        response => {
          const signedUrl = decodeURIComponent(response.data.signed_url)
          const contentType = new URL(signedUrl).searchParams.get(CONTENT_TYPE)
          return uploadImageAWS(signedUrl, file, { headers: { [CONTENT_TYPE]: contentType } }).then(
            () => signedUrl.split('?')[0],
            ({ response: responseAws }) => {
              const { status, statusText } = responseAws
              commit('SET_STATE_VALUE', {
                field: 'error',
                value: { status, statusText }
              })
            }
          )
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        }
      )
    },
    addCatalog({ commit, dispatch }, { name, file, enabled, onSuccess = ID_FUNC, onFailure = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      dispatch('uploadCatalogFile', file).then(catalogUrl =>
        addCatalog({ name, catalogUrl, enabled }).then(
          () => {
            commit('SET_STATE_VALUE', { field: 'loading', value: false })
            onSuccess('addCatalog')
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
            commit('SET_STATE_VALUE', { field: 'loading', value: false })
            onFailure('addCatalog', status, statusText)
          }
        )
      )
    },
    async editCatalog(
      { commit, dispatch, state },
      { id, name, file, enabled, onSuccess = ID_FUNC, onFailure = ID_FUNC, order }
    ) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const editedCatalog = { name, enabled }
      const oldOrder = state.catalogs.find(x => x.id === id).order
      let catalogUrl = undefined
      if (file) catalogUrl = file instanceof File ? await dispatch('uploadCatalogFile', file) : file.url
      if (order) editedCatalog.order = order
      if (catalogUrl) editedCatalog.catalogUrl = catalogUrl
      editCatalog(id, editedCatalog).then(
        () => {
          if (order) dispatch('changeCatalogsOrder', { newValue: order, oldValue: oldOrder, id })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onSuccess('editCatalog')
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onFailure('editCatalog', status, statusText)
        }
      )
    },
    deleteCatalog({ commit }, { id, onSuccess = ID_FUNC, onFailure = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      deleteCatalog(id).then(
        () => {
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onSuccess('deleteCatalog')
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onFailure('deleteCatalog', status, statusText)
        }
      )
    },
    getAllCatalogsOrder({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getAllCatalogsOrder().then(
        response => {
          const catalogs = response.data
          commit('SET_STATE_VALUE', { field: 'error', value: null })
          commit('SET_STATE_VALUE', { field: 'catalogsRange', value: catalogs })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        }
      )
    },
    changeCatalogsOrder({ commit }, { newValue, oldValue, id }) {
      commit('EDIT_CATALOG_ORDER_VALUE', { newValue, oldValue, id })
    }
  }
}
