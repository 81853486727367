import { getHolidays, addHoliday, deleteHoliday } from '@/services/HolidaysService'

export default {
  namespaced: true,

  state: {
    loading: false,
    currentPage: 1,
    holidays: [],
    sort: {},
    totalRows: null,
    error: null,
    errorDeleteHoliday: false,
    loadingDeleteHoliday: false,
    successDeleteHoliday: false
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_HOLIDAYS(state, { holidays, totalCount, order }) {
      state.holidays = holidays
      state.totalRows = totalCount
      state.loading = false
      state.sort = order
    }
  },

  actions: {
    getHolidays({ commit }, { page = 1, order }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, order, limit: 10 }
      getHolidays(params)
        .then(
          response => {
            const { holidays, totalCount } = response.data
            commit('SET_STATE_VALUE', { field: 'error', value: null })
            commit('SET_HOLIDAYS', { holidays, totalCount, order })
          },
          () => {
            commit('SET_HOLIDAYS', { holidays: [], count: 0 })
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    addHoliday({ commit, dispatch }, body) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      addHoliday(body).then(
        () => {
          dispatch('getHolidays', { page: 1 })
          commit('SET_STATE_VALUE', { field: 'error', value: null })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        }
      )
    },
    deleteHoliday({ commit, dispatch, state }, id) {
      commit('SET_STATE_VALUE', { field: 'loadingDeleteHoliday', value: true })
      deleteHoliday(id).then(
        () => {
          dispatch('getHolidays', { page: state.currentPage })
          commit('SET_STATE_VALUE', { field: 'loadingDeleteHoliday', value: false })
          commit('SET_STATE_VALUE', { field: 'errorDeleteHoliday', value: false })
          commit('SET_STATE_VALUE', { field: 'successDeleteHoliday', value: true })
        },
        ({ response }) => {
          commit('SET_STATE_VALUE', { field: 'loadingDeleteHoliday', value: false })
          commit('SET_STATE_VALUE', { field: 'errorDeleteHoliday', value: response.data })
          commit('SET_STATE_VALUE', { field: 'successDeleteHoliday', value: false })
        }
      )
    },
    resetError({ commit }) {
      commit('SET_STATE_VALUE', { field: 'error', value: null })
    }
  }
}
