import { getDollarPrice, editDollarPrice } from '@/services/DollarService'
import { getPointsEquivalence, editDollarEquivalentPoints } from '@/services/PointsService'
import { getDeliveryPickupPrice, editPickupDeliveryConfig } from '@/services/DeliveryPickupService'
import {
  getDesktopBanners,
  getMobileBanners,
  editDesktopBanners,
  editMobileBanners
} from '@/services/BannersService'
import { getConfiguration, updateConfiguration } from '../../services/ConfigurationsService'

const ID_FUNC = id => id

export default {
  namespaced: true,

  state: {
    deltaDeliveryDays: 0,
    customizationUnderMinExtra: 0,
    deliveryPrice: 0,
    deliveryDiscountPrice: 0,
    dollarPrice: 0,
    dollarPointsPrice: 0,
    pickupPrice: 0,
    deliveryAdvanceEmails: '',
    pointsEquivalence: {},
    authorizedDesktopBanners: [],
    unauthorizedDesktopBanners: [],
    authorizedMobileBanners: [],
    unauthorizedMobileBanners: [],
    bannersLoading: false,
    deliveryLoading: false,
    deliveryDiscountLoading: false,
    dollarLoading: false,
    dollarPointsLoading: false,
    pickupLoading: false,
    deliveryAdvanceEmailsLoading: false,
    customizationUnderMinLoading: false,
    customizationUnderMinSuccess: false,
    customizationUnderMinError: false,
    deltaDeliveryDaysLoading: false,
    deltaDeliveryDaysSuccess: false,
    deltaDeliveryDaysError: false,
    deliverySuccess: false,
    deliveryDiscountSuccess: false,
    dollarSuccess: false,
    dollarPointsSuccess: false,
    pickupSuccess: false,
    deliveryAdvanceEmailsSuccess: false,
    deliveryError: false,
    deliveryDiscountError: false,
    dollarError: false,
    dollarPointsError: false,
    pickupError: false,
    deliveryAdvanceEmailsError: false,
    pointsLoading: false,
    arsToPointsError: false,
    arsToPointsSuccess: false
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    }
  },

  actions: {
    getDollarPrice({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'dollarLoading', value: true })
      getDollarPrice(value).then(
        response => {
          commit('SET_STATE_VALUE', { field: 'dollarPrice', value: response.data.usd.value })
          commit('SET_STATE_VALUE', { field: 'dollarLoading', value: false })
        },
        () => commit('SET_STATE_VALUE', { field: 'dollarLoading', value: false })
      )
    },
    getDeliveryPickupPrice({ commit }) {
      commit('SET_STATE_VALUE', { field: 'deliveryLoading', value: true })
      commit('SET_STATE_VALUE', { field: 'pickupLoading', value: true })
      commit('SET_STATE_VALUE', { field: 'deliveryDiscountLoading', value: true })
      commit('SET_STATE_VALUE', { field: 'dollarPointsLoading', value: true })
      getDeliveryPickupPrice().then(
        response => {
          const configKeys = response.data.pickup_delivery_configs.reduce(
            (acc, { key, value }) => ({ ...acc, [key]: value }),
            {}
          )
          commit('SET_STATE_VALUE', { field: 'deliveryPrice', value: configKeys.delivery })
          commit('SET_STATE_VALUE', {
            field: 'deliveryDiscountPrice',
            value: configKeys.delivery_top_discount
          })
          commit('SET_STATE_VALUE', {
            field: 'dollarPointsPrice',
            value: configKeys.dollar_points
          })
          commit('SET_STATE_VALUE', { field: 'pickupPrice', value: configKeys.pickup })
          commit('SET_STATE_VALUE', { field: 'deliveryLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'pickupLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarPointsLoading', value: false })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'deliveryLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'pickupLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarPointsLoading', value: false })
        }
      )
    },
    getDeliveryAdvanceEmails({ commit }) {
      commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsLoading', value: true })
      getConfiguration('advance_verification_emails').then(
        response => {
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmails', value: response.data.value })
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsLoading', value: false })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsLoading', value: false })
        }
      )
    },
    getDeltaDeliveryDays({ commit }) {
      commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysLoading', value: true })
      getConfiguration('delta_delivery_days').then(
        response => {
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDays', value: response.data.value })
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysLoading', value: false })
        },
        () => commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysLoading', value: false })
      )
    },
    editDeltaDeliveryDays({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysLoading', value: true })
      updateConfiguration('delta_delivery_days', { value }).then(
        response => {
          this.deltaDeliveryDays = response.data.value
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysError', value: false })
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysError', value: true })
          commit('SET_STATE_VALUE', { field: 'deltaDeliveryDaysSuccess', value: false })
        }
      )
    },
    getCustomizationUnderMinExtra({ commit }) {
      commit('SET_STATE_VALUE', { field: 'customizationUnderMinLoading', value: true })
      getConfiguration('customization_under_minimum_extra_cost').then(
        response => {
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinExtra', value: response.data.value })
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinLoading', value: false })
        },
        () => commit('SET_STATE_VALUE', { field: 'customizationUnderMinLoading', value: false })
      )
    },
    editCustomizationUnderMinExtra({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'customizationUnderMinLoading', value: true })
      updateConfiguration('customization_under_minimum_extra_cost', { value }).then(
        response => {
          this.customizationUnderMinExtra = response.data.value
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinError', value: false })
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinError', value: true })
          commit('SET_STATE_VALUE', { field: 'customizationUnderMinSuccess', value: false })
        }
      )
    },
    editDeliveryPrice({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'deliveryLoading', value: true })
      editPickupDeliveryConfig('delivery', value).then(
        () => {
          this.deliveryPrice = value
          commit('SET_STATE_VALUE', { field: 'deliveryLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryError', value: false })
          commit('SET_STATE_VALUE', { field: 'deliverySuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'deliveryLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryError', value: true })
          commit('SET_STATE_VALUE', { field: 'deliverySuccess', value: false })
        }
      )
    },
    editDeliveryDiscountPrice({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'deliveryDiscountLoading', value: true })
      editPickupDeliveryConfig('delivery_top_discount', value).then(
        () => {
          this.deliveryDiscountPrice = value
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountError', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountError', value: true })
          commit('SET_STATE_VALUE', { field: 'deliveryDiscountSuccess', value: false })
        }
      )
    },
    editDollarPrice({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'dollarLoading', value: true })
      editDollarPrice(value).then(
        response => {
          this.dollarPrice = response.data.usd.value
          commit('SET_STATE_VALUE', { field: 'dollarLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarError', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'dollarLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarError', value: true })
          commit('SET_STATE_VALUE', { field: 'dollarSuccess', value: false })
        }
      )
    },
    editDollarPointsPrice({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'dollarPointsLoading', value: true })
      editPickupDeliveryConfig('dollar_points', value).then(
        () => {
          this.dollarPoints = value
          commit('SET_STATE_VALUE', { field: 'dollarPointsLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarPointsError', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarPointsSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'dollarPointsLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'dollarPointsError', value: true })
          commit('SET_STATE_VALUE', { field: 'dollarPointsSuccess', value: false })
        }
      )
    },
    editPickupPrice({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'pickupLoading', value: true })
      editPickupDeliveryConfig('pickup', value).then(
        () => {
          this.pickupPrice = value
          commit('SET_STATE_VALUE', { field: 'pickupLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'pickupError', value: false })
          commit('SET_STATE_VALUE', { field: 'pickupSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'pickupLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'pickupError', value: true })
          commit('SET_STATE_VALUE', { field: 'pickupSuccess', value: false })
        }
      )
    },
    editDeliveryAdvanceEmails({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsLoading', value: true })
      updateConfiguration('advance_verification_emails', { value }).then(
        () => {
          this.deliveryAdvanceEmails = value
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsSuccess', value: true })
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsError', value: false })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsSuccess', value: false })
          commit('SET_STATE_VALUE', { field: 'deliveryAdvanceEmailsError', value: true })
        }
      )
    },
    getBanners({ commit }) {
      commit('SET_STATE_VALUE', { field: 'bannersLoading', value: true })
      Promise.all([
        getDesktopBanners(true).then(response => {
          commit('SET_STATE_VALUE', { field: 'authorizedDesktopBanners', value: response.data.banners })
        }),
        getDesktopBanners().then(response => {
          commit('SET_STATE_VALUE', { field: 'unauthorizedDesktopBanners', value: response.data.banners })
        }),
        getMobileBanners(true).then(response => {
          commit('SET_STATE_VALUE', { field: 'authorizedMobileBanners', value: response.data.banners })
        }),
        getMobileBanners().then(response => {
          commit('SET_STATE_VALUE', { field: 'unauthorizedMobileBanners', value: response.data.banners })
        })
      ]).then(() => {
        commit('SET_STATE_VALUE', { field: 'bannersLoading', value: false })
      })
    },
    publishBanners(
      { commit },
      {
        authDesktopBanners,
        unauthDesktopBanners,
        authMobileBanners,
        unauthMobileBanners,
        onSuccess = ID_FUNC,
        onFailure = ID_FUNC
      }
    ) {
      commit('SET_STATE_VALUE', { field: 'bannersLoading', value: true })
      Promise.all([
        editDesktopBanners(authDesktopBanners, true),
        editDesktopBanners(unauthDesktopBanners, false),
        editMobileBanners(authMobileBanners, true),
        editMobileBanners(unauthMobileBanners, false)
      ]).then(
        () => {
          commit('SET_STATE_VALUE', { field: 'bannersLoading', value: false })
          onSuccess('publishBanners')
        },
        () => onFailure('publishBanners')
      )
    },
    resetValue({ commit }, field) {
      commit('SET_STATE_VALUE', { field, value: null })
    },
    getPointsEquivalence({ commit }) {
      commit('SET_STATE_VALUE', { field: 'pointsLoading', value: true })
      getPointsEquivalence().then(
        response => {
          commit('SET_STATE_VALUE', { field: 'pointsEquivalence', value: response.data })
          commit('SET_STATE_VALUE', { field: 'pointsLoading', value: false })
        },
        () => commit('SET_STATE_VALUE', { field: 'pointsLoading', value: false })
      )
    },
    editDollarEquivalentPoints({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'pointsLoading', value: true })
      editDollarEquivalentPoints(value).then(
        () => {
          commit('SET_STATE_VALUE', { field: 'pointsLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'arsToPointsError', value: false })
          commit('SET_STATE_VALUE', { field: 'arsToPointsSuccess', value: true })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'pointsLoading', value: false })
          commit('SET_STATE_VALUE', { field: 'arsToPointsError', value: true })
          commit('SET_STATE_VALUE', { field: 'arsToPointsSuccess', value: false })
        }
      )
    }
  }
}
