export const pageSizeDefault = 10

export const elementUITypes = {
  input: 'el-input',
  select: 'el-select',
  switch: 'el-switch',
  radioGroup: 'el-radio-group'
}

export const orderOptions = { ascending: 'asc', descending: 'desc' }
