import { getRoles } from '@/services/RolesService'
import {
  createBOUser,
  deleteBOUser,
  editBOUser,
  getBOUserDetails,
  getBOUsers
} from '@/services/BOUsersService'

export default {
  namespaced: true,

  state: {
    BOUserDetails: null,
    BOUsers: [],
    currentPage: 1,
    error: null,
    errorCode: null,
    loading: null,
    roles: null,
    sort: {},
    success: null,
    totalRows: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_BOUSERS(state, { BOUsers, count, sort }) {
      state.BOUsers = BOUsers
      state.totalRows = count
      state.loading = false
      state.sort = sort
    },
    SET_SUCCESS(state) {
      state.success = true
      state.error = null
      state.errorCode = null
      state.loading = false
    },
    SET_ERROR(state, { error = true, errorCode = null }) {
      state.success = null
      state.error = error
      state.loading = false
      state.errorCode = errorCode
    }
  },

  actions: {
    hydrateBOUserDetails({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return getBOUserDetails(id).then(
        response => {
          commit('SET_STATE_VALUE', { field: 'BOUserDetails', value: response })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateBOUsersConfig({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return getRoles().then(
        response => {
          const { roles } = response.data
          const mappedRoles = roles.map(role => ({ label: role.name, value: role.id }))
          commit('SET_STATE_VALUE', { field: 'roles', value: mappedRoles })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateBOUsers({ commit }, { page = 1, filters = {}, sort }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters }
      if (sort.by && sort.order) {
        Object.assign(params, { order_column: sort.by, order: sort.order })
      }
      getBOUsers(params).then(
        response => {
          const { BOUsers, count } = response.data
          commit('SET_BOUSERS', { BOUsers, count, sort })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    createBOUser({ commit }, body) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      createBOUser(body).then(
        response => {
          commit('SET_SUCCESS')
          commit('SET_STATE_VALUE', { field: 'BOUserDetails', value: response })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    editBOUser({ commit }, { id, body }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      editBOUser(id, body).then(
        response => {
          commit('SET_SUCCESS')
          commit('SET_STATE_VALUE', { field: 'BOUserDetails', value: response })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    deleteBOUser({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return deleteBOUser(id).then(
        () => {
          commit('SET_SUCCESS')
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    resetError({ commit }) {
      commit('SET_STATE_VALUE', { field: 'error', value: null })
    },
    resetSuccess({ commit }) {
      commit('SET_STATE_VALUE', { field: 'success', value: null })
    }
  }
}
