import { CamelcaseSerializer } from 'cerealizr'

import { api } from '@/config/api'

const serializer = new CamelcaseSerializer({
  descriptor: {
    product: value => {
      const attributeQuantity = 3
      const serializeValue = serializer.serialize(value)
      const attributeSummary = [...Array(attributeQuantity).keys()].map(attrNum => {
        const attribute = serializeValue[`attribute${attrNum + 1}`]
        const description = serializeValue[`elementDescription${attrNum + 1}`]
        return attribute ? `${attribute}: ${description}` : ''
      })
      return { ...value, summary: attributeSummary.join('/ ') }
    }
  }
})

const serializeUsersResponse = response => {
  const { count, page, total_pages: totalPages, stock_reservations: reservations } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      reservations: reservations.map(obj => serializer.serialize(obj))
    }
  }
}

export const getReservations = params =>
  api.get('/backoffice/stock_reservation', { params: { ...params } }).then(serializeUsersResponse)

export const cancelReservation = id => api.put(`/backoffice/stock_reservation/cancel/${id}`)

export const reserve = body => api.post('/backoffice/stock/reserve', body)
