import _ from 'lodash'
import { isError } from '@/utils/api'
import {
  getDiscounts,
  addDiscount,
  getDiscount,
  editDiscount,
  deleteDiscount,
  getGenericProducts,
  getProducts,
  validateDiscounts,
  getDiscountsWithVolume
} from '@/services/DiscountsService'
import { getFamiliesFlat } from '@/services/ProductFamilyService'

import { getLevels } from '@/services/UserService'
import { isGreaterThan } from '@/utils/helpers'

export default {
  namespaced: true,

  state: {
    currentPage: 1,
    currentPageByQuantity: 1,
    discounts: [],
    discountsByQuantity: [],
    currentDiscount: null,
    sort: {},
    sortByQuantity: {},
    totalRows: 0,
    totalRowsByQuantity: 0,
    loading: true,
    loadingByQuantity: true,
    families: [],
    error: false,
    levels: [],
    familiesLoading: true,
    levelsLoading: true,
    loadingProducts: false,
    genericProducts: [],
    variants: []
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_DISCOUNTS(state, { discounts, count, sort }) {
      state.discounts = discounts
      state.totalRows = count
      state.sort = sort
    },
    SET_DISCOUNTS_BY_QUANTITY(state, { discounts, count, sort }) {
      state.discountsByQuantity = discounts
      state.totalRowsByQuantity = count
      state.sortByQuantity = sort
    }
  },

  actions: {
    async getDiscount({ commit }, { id }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const response = await getDiscount(id)

      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_STATE_VALUE', { field: 'currentDiscount', value: null })
      } else {
        const { data } = response
        let levels = []
        if (!data.allPartners) {
          const totalLengthLevels = data.discountsLevels?.length > 0
          levels = totalLengthLevels ? data.discountsLevels.map(x => parseInt(x.levelId)) : []
        }

        let families = []
        if (!data.allFamilies) {
          const totalLengthFamilies = data.discountsFamilies?.length > 0
          families = totalLengthFamilies ? data.discountsFamilies.map(x => x.familyId) : []
        }
        let products = []
        if (data.discountRanges?.length) {
          data.discountRanges.forEach(discount => {
            const { discountRangeProduct } = discount
            if (discountRangeProduct?.length) {
              products = products.concat(
                discountRangeProduct.map(e => ({
                  ...e.product,
                  ...e,
                  id: e.productId,
                  productId: e.productId
                }))
              )
            }
          })
          products = _.uniqBy(products, 'productId')
        }

        commit('SET_STATE_VALUE', {
          field: 'currentDiscount',
          value: { ...data, levels, families, products }
        })

        commit('SET_STATE_VALUE', { field: 'error', value: null })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },

    // eslint-disable-next-line no-unused-vars
    async getDiscountByName({ commit }, { filters = {} }) {
      const response = await getDiscounts(filters)
      const { rows, discountsWithRanges } = response.data
      return rows[0] || discountsWithRanges?.rows[0]
    },

    async getDiscounts({ commit }, { page = 1, filters = {}, sort } = {}) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      const params = {
        page,
        ...filters,
        sortBy: sort?.by ? sort.by : null,
        sortOrder: sort?.order ? sort.order : null,
        limit: 10
      }
      const response = await getDiscounts(params)

      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_DISCOUNTS', { discounts: [], count: 0, sort: {} })
      } else {
        const { rows, count } = response.data

        const discountsMapped = rows.map(attribute => {
          let levels = 'Todas'
          if (!attribute.allPartners) {
            const totalLengthLevels = attribute.discountsLevels?.length > 0
            levels = totalLengthLevels
              ? attribute.discountsLevels.map(x => x.levelData?.level).join(', ')
              : ''
          }

          let families = 'Todas'
          if (!attribute.allFamilies) {
            const totalLengthFamilies = attribute.discountsFamilies?.length > 0
            families = totalLengthFamilies
              ? attribute.discountsFamilies.map(x => x.familyData?.description).join(', ')
              : ''
          }

          return {
            families,
            levels,
            ...attribute
          }
        })

        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_DISCOUNTS', { discounts: discountsMapped, count, sort })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    async getDiscountsByQuantity({ commit }, { page = 1, filters = {}, sort } = {}) {
      commit('SET_STATE_VALUE', { field: 'loadingByQuantity', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPageByQuantity', value: page })
      const params = {
        page,
        ...filters,
        enabled: 'all',
        sortBy: sort?.by ? sort.by : null,
        sortOrder: sort?.order ? sort.order : null,
        limit: 10
      }
      const response = await getDiscountsWithVolume(params)

      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_DISCOUNTS_BY_QUANTITY', { discounts: [], count: 0, sort: {} })
      } else {
        const { rows, count } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_DISCOUNTS_BY_QUANTITY', {
          discounts: rows,
          count,
          sort
        })
      }

      commit('SET_STATE_VALUE', { field: 'loadingByQuantity', value: false })
    },
    async getFamilies({ commit }, todos = true) {
      commit('SET_STATE_VALUE', { field: 'familiesLoading', value: true })
      const response = await getFamiliesFlat()
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_STATE_VALUE', { field: 'families', value: [] })
      } else {
        const { families } = response.data
        const familiesOrdened = [
          ...families.sort((op1, op2) => isGreaterThan(op1.description, op2.description))
        ]
        const newCollection = todos
          ? [{ description: 'Todas', id: 'allFamilies' }, ...familiesOrdened]
          : familiesOrdened
        commit('SET_STATE_VALUE', {
          field: 'families',
          value: newCollection
        })
      }
      commit('SET_STATE_VALUE', { field: 'familiesLoading', value: false })
    },
    async getLevels({ commit }, todos = true) {
      commit('SET_STATE_VALUE', { field: 'levelsLoading', value: true })
      const response = await getLevels()
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_STATE_VALUE', { field: 'levels', value: [] })
      } else {
        const { data } = response
        const levelsOrdened = [...data.sort((op1, op2) => isGreaterThan(op1.order, op2.order))]
        const newCollection = todos ? [{ level: 'Todas', id: 'allLevels' }, ...levelsOrdened] : levelsOrdened
        commit('SET_STATE_VALUE', {
          field: 'levels',
          value: newCollection
        })
      }
      commit('SET_STATE_VALUE', { field: 'levelsLoading', value: false })
    },
    async getGenericProducts({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loadingProducts', value: true })
      const response = await getGenericProducts()
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_STATE_VALUE', { field: 'genericProducts', value: [] })
      } else {
        const { data } = response

        commit('SET_STATE_VALUE', { field: 'genericProducts', value: data.genericProducts })
      }
      commit('SET_STATE_VALUE', { field: 'loadingProducts', value: false })
    },

    async getVariant({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loadingProducts', value: true })
      const response = await getProducts(id)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_STATE_VALUE', { field: 'variants', value: [] })
      } else {
        const { data } = response
        const dataMapped = [{ id: 'all', element_description1: 'Todos' }, ...data.variants]
        commit('SET_STATE_VALUE', { field: 'variants', value: dataMapped })
      }
      commit('SET_STATE_VALUE', { field: 'loadingProducts', value: false })
    },
    async editDiscount({ commit }, data) {
      const response = await editDiscount(data)
      let success = true

      if (isError(response.status) || !response?.data?.id) {
        const { status, statusText } = response
        success = false
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
      }
      return { success, discountId: response.data.discountId }
    },

    async addDiscount({ commit }, data) {
      const response = await addDiscount(data)
      let success = true

      if (isError(response.status) || !response?.data?.discountId) {
        const { status, statusText } = response
        success = false
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
      }
      return { success, discountId: response.data.discountId }
    },
    async deleteDiscount({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const response = await deleteDiscount(id)
      let success = true

      if (isError(response.status)) {
        const { status, statusText } = response
        success = false
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
      return { success }
    },
    // eslint-disable-next-line no-empty-pattern
    async validateDiscounts({}, data) {
      const dataReduced = { ...data }
      dataReduced.products = data.products.map(p => ({ id: p.id }))
      const { data: response } = await validateDiscounts(dataReduced)

      return response
    }
  }
}
