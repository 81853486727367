import { getComplaints, getComplaintDetails, editComplaint } from '@/services/ComplaintsService'

export default {
  namespaced: true,

  state: {
    complaintDetails: null,
    complaints: [],
    currentPage: 1,
    error: null,
    errorCode: null,
    loading: null,
    success: null,
    totalRows: 49
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_COMPLAINTS(state, { complaints, count }) {
      state.complaints = complaints
      state.totalRows = count
      state.loading = false
    },
    SET_ERROR(state, { error = true, errorCode = null }) {
      state.success = null
      state.error = error
      state.loading = false
      state.errorCode = errorCode
    }
  },

  actions: {
    hydrateComplaintDetails({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return getComplaintDetails(id).then(
        response => {
          commit('SET_STATE_VALUE', { field: 'complaintDetails', value: response })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateComplaints({ commit }, { page = 1, filters = {} }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters }
      getComplaints(params).then(
        response => {
          const { complaints, count } = response.data
          commit('SET_COMPLAINTS', { complaints, count })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    editComplaint({ dispatch }, { complaint, onFinish }) {
      editComplaint(complaint.id, complaint).then(
        () => {
          dispatch('hydrateComplaints', { page: 1 })
          onFinish(true)
        },
        () => {
          dispatch('hydrateComplaints', { page: 1 })
          onFinish()
        }
      )
    }
  }
}
