export default {
  kits: 'Kits',
  variant: 'Variante',
  newKit: 'Nuevo Kit',
  addKit: 'Agregar Kit',
  editKit: 'Editar Kit',
  saveKit: 'Guardar Kit',
  listKits: 'Listado de Kits',
  published: 'Publicado',
  successEditKit: 'Kit editado con éxito',
  successAddKit: 'Kit agregado con éxito',
  kitName: 'Nombre del Kit',
  kitDetail: 'Detalle del Kit',
  additionalInformation: 'Información complementaria',
  variantName: 'Nombre de la Variante',
  saveVariant: 'Guardar Variante',
  editVariant: 'Editar Variante',
  quantityAvailable: 'Cantidad disponible',
  quantityError: 'La cantidad debe ser menor o igual a: ',
  noStockVariant: 'Variantes sin stock',
  search: 'Buscar',
  variantsQuantity: 'Cantidad de variantes',
  products: 'Productos',
  productsIncludes: 'Productos que incluye',
  msg: {
    minQuantityVariant: 'Es necesario 2 productos para editar la variante',
    nameVariant: 'Es necesario indicar nombre de variante',
    nameAvailableError: 'Ya existe un kit con el nombre seleccionado',
    finishEditVariant: 'Debe finalizar la edición de la variante activa',
    completeForm: 'Debe completar todo los campos solicitados',
    nameVariantNoAvailable: 'Ya existe una variante con el nombre seleccionado en este kit',
    isVariantsActiveError: 'Para publicar el kit, debe existir al menos una variante publicada'
  },
  fields: {
    name: 'Nombre',
    description: 'Descripción',
    family: 'Categoría',
    featured: 'Destacado',
    subfamily: 'Subcategoría',
    price: 'Precio (CLP)',
    published: 'Publicado',
    noPublished: 'No publicado',
    publishedAt: 'Publicado en',
    height: 'Altura (cm)',
    length: 'Longitud (cm)',
    width: 'Ancho (cm)',
    unitWeight: 'Peso x un.(grs)',
    unitsPerBox: 'Un. x caja',
    minimumOrderQuantity: 'Cantidad mínima',
    expressDeliveryCount: 'Muestra simple',
    printingTypes: 'Tipo de impresión',
    productDescription: 'Descripción',
    tag: 'Tags',
    qrUrl: 'QR producto',
    tax: 'Impuestos',
    buyBySize: 'Compra por curva',
    enable: 'Habilitar'
  },

  failure: {
    productsVariantsNoPublished:
      'No se puede habilitar esta variante porque algún producto que la compone no se encuentra publicado'
  }
}
