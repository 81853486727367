export default {
  btnDeleteFilters: 'Quitar filtros',
  btnSearch: 'Buscar',
  cancel: 'Cancelar',
  columnDetailName: 'Detalle',
  deleteColumn: 'Eliminar',
  deleteElement: 'Esta acción borrará completamente el elemento. ¿Está seguro de eliminarlo?',
  filterWithoutOptions: 'Sin opciones',
  types: {
    boolean: { true: 'Sí', false: 'No' }
  },
  showDetailText: 'Ver',
  synchronized: 'Sincronizado',
  warning: 'Advertencia',
  downloadColumn: 'Descargar',
  columnActionsName: 'Acciones'
}
