import axios from 'axios'
import store from '../store'

import LocalStorageService from '../services/LocalStorageService'

const notAuthorized = 401
const auth = LocalStorageService.getSessionToken()

const headers = {
  'Content-Type': 'application/json'
}

export const api = axios.create({
  timeout: 120000,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: auth ? { ...headers, Authorization: auth } : headers
})

export const setHeader = (header, value) => (api.defaults.headers.common[header] = value)

export const removeHeader = header => delete api.defaults.headers.common[header]

api.interceptors.request.use(request => {
  const token = LocalStorageService.getSessionToken()
  if (token) {
    request.headers.Authorization = token
  }
  return request
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === notAuthorized) {
      store.dispatch('auth/logout')
    }
    return Promise.reject(error)
  }
)

const csvHeaders = {
  'Content-Type': 'multipart/form-data'
}

export const csvApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: auth ? { ...csvHeaders, Authorization: auth } : csvHeaders
})

export const setCsvHeader = (header, value) => (csvApi.defaults.headers.common[header] = value)

export const removeCsvHeader = header => delete csvApi.defaults.headers.common[header]

csvApi.interceptors.request.use(request => {
  const token = LocalStorageService.getSessionToken()
  if (token) {
    request.headers.Authorization = token
  }
  return request
})
