export default {
  configs: 'Configuraciones',
  dashboard: 'Dashboard',
  login: 'Login',
  logout: 'Cerrar sesión',
  orders: 'Pedidos',
  expressorders: 'Preparación muestra simple',
  expressordersdelivery: 'Distribución muestra simple',
  partners: 'Partners',
  products: 'Productos',
  productsWepod: 'Productos Wepod',
  userDetails: 'Detalle de usuario',
  orderDetails: 'Detalle de pedido',
  userManagenent: 'Gestión de Usuarios',
  reservations: 'Reservas',
  techniques: 'Técnicas de aplicación',
  newTechnique: 'Crear Técnica',
  techniqueDetails: 'Detalle de técnicas de aplicación',
  catalogs: 'Catálogos',
  addCatalog: 'Crear catálogo',
  editCatalog: 'Editar catálogo',
  attributes: 'Atributos',
  addAtribute: 'Crear atributo',
  editAttribute: 'Editar atributo',
  roles: 'Roles y permisos',
  BOUsers: 'Usuarios Backoffice',
  newBOUser: 'Crear Usuario',
  BOUserDetails: 'Usuario Backoffice',
  holidays: 'Dias No Laborables',
  advances: 'Adelanto de pedidos',
  advanceDetails: 'Detalle de adelanto',
  complaints: 'Reclamos',
  complaintsDetails: 'Detalle de reclamo',
  productFamilies: 'Familias de Productos',
  reports: 'Reportes',
  domains: 'Dominios Admitidos',
  kits: 'Kits',
  discounts: 'Descuentos',
  printingProfiles: 'Perfiles de impresión',
  logisticOperators: 'Operadores Logísticos',
  operatorRoutes: 'Detalle de operador logístico'
}
