export default {
    title: 'Vendedores',
    addSeller: 'Agregar vendedor',
    allSellers: 'Todos',
    edit: 'Editar',
    add: 'Agregar',
    delete: 'Eliminar',
    fullName: 'Nombre y apellido',
    fullNamePlaceholder: 'Nombre',
    email: 'Email',
    phone: 'Teléfono',
    phonePlaceholder: 'Número de teléfono',
    actions: 'Opciones',
    deleteTitle: 'Estás a punto de eliminar vendedor',
    deleteSubtitle: 'Esta acción es irreversible y no podrás volver atrás.',
    cancel: 'Cancelar',
    save: 'Guardar',
    successAdd: 'Vendedor agregado con éxito',
    successEdit: 'Vendedor editado con éxito',
    noDataText: 'No hay datos disponibles',
    beforeContinue: 'Antes de continuar, debes',
    theEmailExist: 'El correo electrónico ya existe en nuestra base de datos.',
    duplicateEmail: 'Correo duplicado'
  }