export default {
  title: 'Atributos',
  addAttribute: 'Crear atributo',
  noResultMessage: 'No se encontraron atributos',
  id: 'id',
  name: 'Nombre',
  families: 'Familias',
  subattributes: 'Subatributos',
  back: 'Volver',
  addFormTitle: 'Crear atributo',
  editFormTitle: 'Editar atributo',
  addSubattribute: 'Agregar subatributo',
  noSubattributes: 'No hay subatributos',
  save: 'Guardar',
  restore: 'Restablecer',
  confirm: 'Confirmar',
  delete: 'Eliminar',
  edit: 'Editar',
  deleteSubattributeText: 'Está seguro que quiere eliminar el subatributo',
  editSubattributeText: 'Ingrese el nombre del subatributo',
  subattribute: 'Subatributo',
  success: {
    deleteAttribute: 'El atributo fue borrado con exito',
    addAttribute: 'El atributo fue agregado con exito',
    editAttribute: 'El atributo fue guardado con éxito',
    addSubattribute: 'El subatributo fue agregado con éxito',
    editSubattribute: 'El subatributo fue modificado con éxito',
    deleteSubattribute: 'El subatributo fue borrado con éxito'
  },
  failure: {
    deleteAttribute: 'Hubo un error al borrar el atributo',
    addAttribute: 'Hubo un error al agregar el atributo',
    editAttribute: 'Hubo un error al editar el atributo',
    addSubattribute: 'Hubo un error al agregar el subatributo',
    editSubattribute: 'Hubo un error al modificar el subatributo',
    deleteSubattribute: 'Hubo un error al eliminar el subatributo'
  },
  error: {
    alreadyInArray: 'Ya existe un subatributo con ese nombre',
    required: 'Este campo es requerido',
    notSameAttributes: 'No puede haber subatributos con el mismo nombre',
    noEmptySubattribute: 'No puede haber subatributos vacios'
  }
}
