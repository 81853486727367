import camelize from 'camelize'

import { api } from '../config/api'

const serializeAdvanceResponse = response => {
  const { total_count: count, page, total_count: totalPages, advances } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      advances: advances.map(obj => camelize(obj))
    }
  }
}

export const getAdvances = params =>
  api.get('/backoffice/advances', { params: { ...params, limit: 10 } }).then(serializeAdvanceResponse)

export const getAdvanceDetails = id =>
  api.get(`/backoffice/advances/${id}`).then(response => camelize(response.data))

export const getAdvancesStatus = () => api.get('/backoffice/advances_status')

export const sendAdvanceProposal = (id, body) => api.put(`/backoffice/advance/${id}`, body)
