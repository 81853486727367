export const maxLengthShort = 30
export const maxLengthMedium = 40
export const maxLengthLong = 50

export const defaultProvince = { id: '001', name: 'Buenos Aires' }

export const usersColumns = [
  { prop: 'business_name', label: 'Empresa' },
  { prop: 'name', label: 'Nombre' },
  { prop: 'cuit', label: 'CUIT' },
  { prop: 'address', label: 'Dirección' },
  { prop: 'zip_code', label: 'Código Postal' },
  { prop: 'phone_number', label: 'Teléfono' },
  { prop: 'active', label: 'Activo' },
  { prop: 'email', label: 'Email' },
  { prop: 'stateTranslation', label: 'Estado' }
]

export const usersStates = {
  enabled: 'Habilitado',
  disabled: 'Deshabilitado'
}

export const kitsStates = {
  enabled: 'Habilitado',
  disabled: 'Deshabilitado'
}

export const statesFilters = [
  { value: 'enabled', label: 'Habilitado' },
  { value: 'disabled', label: 'Deshabilitado' }
]
