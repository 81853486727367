import _ from 'lodash'

export function productsVariants(products) {
  const result = []

  const productsByColorAndSize = products.reduce((r, a) => {
    if (a.color) {
      r[a.color] = r[a.color] || []
      r[a.color].push(a)
    }
    return r
  }, Object.create(null))

  _.forEach(productsByColorAndSize, (value, key) => {
    const isSomeWepod = productsByColorAndSize[key].find(element => element.wepod)
    const newValue = { ...value[0], wepod: !!isSomeWepod }

    result.push(newValue)
  })

  return result
}
