export default {
  title: 'Operadores Logísticos',
  newOperator: 'Nuevo Operador',
  name: 'Nombre',
  fields: {
    cuit: 'CUIT',
    enabled: 'Habilitado',
    logo: 'Logo'
  },
  noResultMessage: 'No se encontraron operadores logísticos',
  save: 'Guardar',
  saveErrorMessage: 'Hubo un error al intentar guardar el operador logístico',
  fillFormNotice: 'Debe llenar todo el formulario',
  saveSuccess: 'Se guardó el operador logístico exitosamente',
  imageFailure: 'Ocurrió un error al cargar la imagen',
  operatorDetail: 'Detalle de Operador',
  existingOperator: 'Ya existe un operador con ese nombre o CUIT'
}
