import { api } from '@/config/api'

const serializeKitsResponse = response => {
  const { count, page, total_pages: totalPages, kits } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      kits
    }
  }
}

export const getAllKits = ({ ...params }) =>
  api.get('/kits', { params: { ...params, limit: 10 } }).then(response => serializeKitsResponse(response))

export const addKit = params => api.post('/kits', params)

export const getKit = id => api.get(`/kits/${id}`)

export const editKit = params => api.put('/kits', params)

export const editVariantPublished = params => api.put('/edit_variant_published', params)

export const getKitsByExternalId = id => api.get(`/get_kit_by_external_id/${id}`)

export const getKitFamilies = () => api.get('/family/kits', { params: { get_all: true } })
