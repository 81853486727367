export default {
  title: 'Perfiles de impresión',
  printingProfilesName: 'Nombre del perfil',
  newProfile: 'Nuevo perfil',
  newPrintingProfile: 'Nuevo perfil de impresión',
  idColumn: 'id',
  enabledColumn: 'Habilitado',
  useBaseProfileAction: 'Usar de base de perfil',
  noResultMessage: 'No se encontraron templates',
  noEnabledAction: 'Deshabilitar',
  enabledAction: 'Habilitar',
  editAction: 'Editar',
  kst: 'KST',
  viewAction: 'Ver',
  back: 'Volver',
  newPrintProfile: 'Nuevo perfil de impresión',
  editProfile: 'Editar perfil',
  createProfile: 'Crear perfil',
  selectBaseProfile: 'Elegir perfil base',
  form: {
    name: 'Nombre',
    nameProfile: 'Nombre del perfil',
    description: 'Descripción',
    baseProfile: 'Perfil de base',
    selectOne: 'Seleccionar uno',
    save: 'Guardar',
    continue: 'Continuar',
    cancel: 'Cancelar',
    editableFields: 'Campos editables'
  },
  error: {
    addCatalog: 'Hubo un error al agregar el catálogo',
    editCatalog: 'Hubo un error al guardar los cambios',
    uploadFile: 'Hubo un error al querer firmar la url del catálogo',
    awsUploadFile: 'Hubo un error al querer guardar el catálogo',
    required: 'Campo requerido'
  }
}
