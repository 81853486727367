import { getAllImagesFromS3Bucket } from '@/services/ImageService'
import { createFamily, editFamily } from '@/services/ProductFamilyService'
import { getFamilies } from '../../services/ProductFamilyService'

export default {
  namespaced: true,
  state: {
    productFamilies: [],
    icons: [],
    loadingIcons: false,
    loadingFamilies: false,
    createFamilyLoading: false,
    editFamilyLoading: false
  },
  mutations: {
    SET_PRODUCT_FAMILIES(state, productFamilies) {
      state.productFamilies = productFamilies
    },
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_IMAGE_CLASS_VALUE(state, url) {
      state.icons.forEach(icon => {
        icon.class = ''
      })
      state.icons.find(icon => icon.url === url).class = 'selected'
    },
    CLEAR_IMAGE_CLASS_VALUE(state) {
      state.icons.forEach(icon => {
        icon.class = ''
      })
    },
    UPDATE_FAMILY(state, family) {
      const familyId = state.productFamilies.findIndex(el => el.id === family.id)
      if (familyId !== -1) state.productFamilies[familyId] = family
    }
  },
  actions: {
    getFamilies({ commit }, onSuccess) {
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: true })
      getFamilies()
        .then(response => {
          commit('SET_PRODUCT_FAMILIES', response.data?.families)
          if (onSuccess) onSuccess()
        })
        .finally(() => commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: false }))
    },
    createFamily({ commit }, { family, onSuccess, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'createFamilyLoading', value: true })
      createFamily(family)
        .then(
          response => onSuccess(response?.data?.id),
          () => onFailure()
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'createFamilyLoading', value: false }))
    },
    editFamily({ commit }, { family, form, onSuccess, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'editFamilyLoading', value: true })
      editFamily(family.id, form).then(
        response => {
          onSuccess(response?.data?.id)
          commit('UPDATE_FAMILY', form)
        },
        () => onFailure()
      )
      commit('SET_STATE_VALUE', { field: 'editFamilyLoading', value: false })
    },
    getImages({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loadingIcons', value: true })
      getAllImagesFromS3Bucket()
        .then(response => {
          commit('SET_STATE_VALUE', {
            field: 'icons',
            value: response.data.map(data => new Object({ url: data, class: '' }))
          })
        })
        .finally(() => commit('SET_STATE_VALUE', { field: 'loadingIcons', value: false }))
    },
    setImageSelected({ commit }, url) {
      commit('SET_IMAGE_CLASS_VALUE', url)
    },
    clearImageSelected({ commit }) {
      commit('CLEAR_IMAGE_CLASS_VALUE')
    }
  }
}
