import { getReports, getReport, addReport, editReport, downloadReport } from '@/services/ReportsService'

const ID_FUNC = x => x

export default {
  namespaced: true,

  state: {
    reports: [],
    report: null,
    currentPage: 1,
    total: 0,
    count: 0,
    loading: false,
    error: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    }
  },

  actions: {
    getReports({ commit }, { page = 1 }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      getReports({ page })
        .then(
          response => {
            const { reports, totalCount, count } = response.data
            commit('SET_STATE_VALUE', { field: 'error', value: null })
            commit('SET_STATE_VALUE', { field: 'reports', value: reports })
            commit('SET_STATE_VALUE', { field: 'total', value: totalCount })
            commit('SET_STATE_VALUE', { field: 'count', value: count })
          },
          ({ response }) => {
            const { status, statusText } = response
            commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    getReport({ commit }, { id }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getReport(id).then(response => {
        commit('SET_STATE_VALUE', {
          field: 'report',
          value: response.data
        })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      })
    },
    addReport({ commit }, { report, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      addReport({ report })
        .then(
          () => onFinish(true, 'addReport'),
          ({ response }) => {
            commit('SET_STATE_VALUE', { field: 'error', value: response.data.internal_code })
            onFinish(false, 'addReport')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    editReport({ commit }, { report, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      editReport({ report })
        .then(
          () => onFinish(true, 'editReport'),
          ({ response }) => {
            commit('SET_STATE_VALUE', { field: 'error', value: response.data.internal_code })
            onFinish(false, 'editReport')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    deleteReport({ commit }, { report, onFinish = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      editReport({ report })
        .then(
          () => {
            onFinish(true, 'deleteReport')
          },
          ({ response }) => {
            commit('SET_STATE_VALUE', { field: 'error', value: response.data.internal_code })
            onFinish(false, 'deleteReport')
          }
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    downloadReport({ commit }, { id, filename }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      // eslint-disable-next-line no-unused-vars
      downloadReport(id, filename).then(response => {
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      })
    }
  }
}
