export default {
  back: 'Volver',
  closeDate: 'Ultima actualizacion del reclamo',
  closeComplaintSuccess: 'Reclamo actualizado de manera exitosa.',
  closeComplaintFailure: 'Ocurrió un error al actualizar el reclamo',
  complaintDate: 'Fecha de Reclamo',
  complaintDateShort: 'Fecha Reclamo',
  complaintId: '# de Reclamo',
  complaintModals: {
    rejectComplaintModalTitle: 'Confirmar rechazo de reclamo.',
    rejectComplaintModalMessage: 'Una vez rechazado, el reclamo quedará registrado como rechazado.',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    closeComplaintModalTitle: 'Confirmar resolución del reclamo',
    closeComplaintModalMessage: 'Una vez resuelto, el reclamo quedará registrado como Cerrado',
    inProgressComplaintModalTitle: 'Confirmar gestión del reclamo',
    inProgressComplaintModalMsg: 'Una vez pasado a gestión, el reclamo quedará registrado como "En gestión"'
  },
  complaintQuantity: 'Cantidad Reclamada',
  complaintQuantityLong: 'Cantidad de unidades afectadas',
  complaintQuantityTotal: 'Cantidad comprada',
  complaintRecategorization: 'Recategorización tipo reclamo',
  complaintType: 'Reclamo del Partner',
  detail: 'Detalle',
  detailedProblem: 'Descripción detallada del problema',
  downloadImages: 'Descargar Imágenes',
  emailPartner: 'Email Partner',
  image: 'Imagen',
  images: 'Imágenes adjuntas',
  noResultMessage: 'No se encontraron Reclamos',
  orderId: 'Número de pedido',
  orderIdPlaceholder: 'Número de pedido...',
  options: {
    reject: 'Rechazar',
    close: 'Resuelto',
    inProgress: 'En gestión'
  },
  partner: 'Partner',
  partnerComments: 'Comentarios del Partner',
  partnerEmail: 'Email Partner',
  partnerPlaceholder: 'Partner...',
  problemType: 'Tipo de problema',
  product: 'Producto',
  reassingProblem: 'Reasignar tipo de problema',
  rejectComplaintSuccess: 'Reclamo rechazado de manera exitosa.',
  rejectComplaintFailure: 'Ocurrió un error al rechazar el reclamo',
  states: {
    active: 'Activo',
    cancelled: 'Cancelado',
    closed: 'Resuelto',
    rejected: 'Rechazado',
    in_progress: 'En gestión'
  },
  status: 'Estado',
  title: 'Reclamos',
  titles: {
    problemDetails: 'Especificación del problema',
    complaintDetails: 'Detalle de reclamo'
  }
}
