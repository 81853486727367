import configurations from './configurations'
import errors from './errors'
import imageSelection from './imageSelection'
import list from './list'
import login from './login'
import notifications from './notifications'
import orderDetails from './orderDetails'
import orders from './orders'
import productDetails from './productDetails'
import products from './products'
import reservations from './reservations'
import routes from './routes'
import techniques from './techniques'
import userDetails from './userDetails'
import users from './users'
import validations from './validations'
import catalogs from './catalogs'
import catalogDetails from './catalogDetails'
import attributes from './attributes'
import roles from './roles'
import holidays from './holidays'
import BOUsers from './BOUsers'
import advances from './advances'
import complaints from './complaints'
import productFamilies from './productFamilies'
import reports from './reports'
import kits from './kits'
import discounts from './discounts'
import printingProfiles from './printingProfiles'
import logisticOperators from './logisticOperators'
import sellersList from './sellersList'

export default {
  configurations,
  errors,
  imageSelection,
  list,
  login,
  notifications,
  orderDetails,
  orders,
  productDetails,
  products,
  reservations,
  routes,
  techniques,
  userDetails,
  users,
  validations,
  catalogs,
  catalogDetails,
  attributes,
  roles,
  holidays,
  BOUsers,
  advances,
  complaints,
  productFamilies,
  reports,
  kits,
  discounts,
  printingProfiles,
  logisticOperators,
  sellersList
}
