export default {
  advanceRequest: 'Solicitud de adelanto:',
  appliedDiscount: 'Descuento aplicado:',
  back: 'Volver',
  businessDays: 'Días hábiles',
  continue: 'Continuar',
  date: 'Fecha',
  downloadCustomization: 'Descargar personalización',
  email: 'Email partner',
  newProposal: 'Nueva propuesta',
  noResultMessage: 'No se encontraron resultados para su búsqueda.',
  originalProduction: 'Producción original:',
  originals: 'Originales*',
  personalization: 'Personalización',
  product: 'Producto',
  productionDays: 'Días hábiles producción',
  proposalBusinessDays: 'Días hábiles',
  proposalBusinessDaysPlaceholdr: 'Cantidad de días hábiles',
  proposalPrice: 'Monto asociado ARS',
  proposalPricePlaceholder: '1,000,000.00',
  proposalItemSendSuccess: 'Propuesta de adelanto de pedido enviada con éxito.',
  proposalCartSendSuccess: 'Propuesta de adelanto del carrito de compras enviada con éxito.',
  proposalSendFailure: 'Ocurrió un error al enviar la propuesta',
  notifyPartner: 'Se notificará al partner vía email a {email}',
  proposed: 'Propuesto*',
  quantity: 'Cantidad',
  requestDate: 'Fecha de solicitud:',
  requested: 'Solicitado*',
  send: 'Enviar',
  sendedResponse: 'Respuesta enviada el {date}',
  sku: 'SKU',
  state: 'Estado',
  customizationUnderMinimum: 'Personalización debajo de los mínimos: ',
  states: {
    accepted: 'Aceptado por Partner',
    cancelled: 'Cancelado',
    expired: 'Vencido',
    pending_partner: 'Pendiente Partner',
    pending_zecat: 'Pendiente Zecat'
  },
  titleCart: 'Detalle de adelanto del carrito de compras',
  titleItem: 'Detalle de adelanto',
  total: 'Monto total: ARS',
  variant: 'Variante',
  validateBetween: 'Cantidad de días entre ',
  validateMinValue: 'El monto debe ser mayor o igual a 0',
  validateRequired: 'El campo es requerido'
}
