export default {
  welcome: 'Bienvenido a Zecat',
  login: 'Login',
  userLabel: 'Ingrese su usuario',
  username: 'Usuario',
  passwordLabel: 'Ingrese su contraseña',
  password: 'Contraseña',
  errorTitle: 'Error de credenciales',
  errorText: 'El usuario o la contraseña son incorrectas',
  inactiveUser: 'El usuario se encuentra deshabilitado. Comunicarse con un administrador'
}
