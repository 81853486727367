<template lang="pug">
.sidebar(:class='{ minimized }' @click.self='handleMinimize')
  .user.row.middle.m-bottom-3(v-if='currentUser' @click='handleMinimize')
    img.user-image(v-if='currentUser.image' :src='currentUser.image' :alt='currentUser.name')
    transition(name='fade')
      span.user-name.text-small.white.m-left-2.width-100(v-show='!minimized')
        | {{ currentUser.name }}
  el-menu.menu(mode='vertical' :background-color='colors.white' :active-text-color='colors.lightCrimson')
    el-menu-item.menu-item.row.middle.center(
      v-for='(link, index) in menuLinks'
      v-if='checkPermission(link.meta.permission)'
      :key='`${link.name}-${index}`'
      :title='minimized && $t(`routes.${link.name}`)'
      :index='index.toString()'
      :style='menuItemStyle'
      :class='{ "is-active": currentPath === link.path }'
    )
      router-link.menu-link.dark-gray.row.middle.width-100(:to='{ name: link.name }')
        i.icon-link(:class='link.icon')
        transition(name='fade')
          span.menu-link-text.width-100.m-left-1(v-show='!minimized')
            | {{ $t(`routes.${link.name}`) }}
    el-menu-item.menu-item.row.middle.center.width-100(
      :style='menuItemStyle'
      :title='minimized && $t("routes.logout")'
      @click='logout'
    )
      .menu-link.row.middle.width-100
        i.icon-link.el-icon-circle-close
        transition(name='fade')
          span.menu-link-text.dark-gray.m-left-1.width-100(v-show='!minimized')
            | {{ $t('routes.logout') }}
</template>

<script>
// TODO: Logic to support nested links
import { mapState, mapActions } from 'vuex'

import { lightCrimson, white } from '@/scss/variables/colors.scss'

import { menuLinks } from '@/config/routes'

import roles from '@/mixins/roles'

export default {
  mixins: [roles],
  data() {
    return {
      menuLinks,
      colors: { lightCrimson, white },
      minimized: false
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.auth.currentUser
    }),
    currentPath() {
      return this.$route.path
    },
    menuItemStyle() {
      return { 'padding-left': 0, 'background-color': 'inherit' }
    }
  },
  methods: {
    ...mapActions({ logout: 'auth/logout' }),
    handleMinimize() {
      this.minimized = !this.minimized
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/variables/colors';

$sidebar-width: 230px;

.sidebar {
  max-width: $sidebar-width;
  overflow: hidden;
  transition: max-width 0.3s ease 0.2s;

  &.minimized {
    max-width: 60px;

    .icon-link {
      transform: scale(1.5);
    }

    .menu-link {
      padding: 0 0 0 22px;
    }

    .user {
      padding: 5px 7px;
    }
  }
}

.menu {
  height: 100%;
  user-select: none;
  width: $sidebar-width;
}

.user {
  background-color: $abbey;
  padding: 20px 10px;
  width: $sidebar-width;
}

.user-image {
  border: solid 1px $white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-menu-item {
  padding: 0;
}

.menu-item.is-active {
  .menu-link {
    color: inherit;
  }
}

.menu-link {
  height: 100%;
  padding: 0 15px;
  text-decoration: none;
}

.icon-link {
  transition: transform 0.5s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media print {
  .sidebar {
    display: none;
  }
}
</style>
