import { CamelcaseSerializer } from 'cerealizr'
import { api } from '../config/api'

const bannersSerializer = new CamelcaseSerializer({
  descriptor: {
    archive: 'url'
  }
})

const serializeBannersResponse = response => {
  const { status, statusText, data } = response
  const { banners } = data
  return {
    status,
    statusText,
    data: {
      banners: banners.map(elem => bannersSerializer.serialize(elem))
    }
  }
}

export const getDesktopBanners = (forAuthorizedUsers = false) =>
  api.get('banners', { params: { logged: forAuthorizedUsers } }).then(serializeBannersResponse)

export const getMobileBanners = (forAuthorizedUsers = false) =>
  api.get('banners/mobile', { params: { logged: forAuthorizedUsers } }).then(serializeBannersResponse)

export const editDesktopBanners = (banners, forAuthorizedUsers = false) =>
  api.put('backoffice/banners', { banners }, { params: { logged: forAuthorizedUsers } })

export const editMobileBanners = (banners, forAuthorizedUsers = false) =>
  api.put('backoffice/banners/mobile', { banners }, { params: { logged: forAuthorizedUsers } })
