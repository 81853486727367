import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: false,
    themes: {
      // ------------------- por tema oscuro/claro ----- //
      light: {
        primary: '#50535C',
        secondary: '#BE7020',
        primaryBack: '#F7F7F7',
        secondaryBack: '#FFFFFF',
        // ------------------- de evento ----- //
        accent: '#50B8B1',
        error: '#F44336',
        success: '#4CAF50',
        // ------------------- colores ----- //
        headerColumn: '#E5E5E5',
        subHeaderColumn: '#EFEEEE',
        Preparar: '#D41122',
        Devuelto: '#FF7F27',
        in_production: '#68CD86',
        darkBg: '#3D3D3D',
        insti: '#32BBED',
        txtColor: '#717171',
        lightInsti: '#5BC9F1',
        lightGreen: '#4ECB4A',
        redBg: '#D41122',
        redBgTitle: '#DE5460',
        redBgBtn: '#E15B67',
        superLightInsti: '#84D6F4',
        fucsia: '#EE3D8F',
        mandarine: '#F79420',
        sun: '#FFD100',
        lime: '#D6E031',
        lavander: '#9283BE',
        cherry: '#C2185B',
        tomatoe: '#EF5350',
        orange: '#EF6C00',
        olive: '#6EA100',
        darkMint: '#008388',
        grape: '#6A1B99',
        mint: '#51B8B1',
        mintDarker: '#389A93',
        // ------------------- neutros ----- //
        black: '#000000',
        tundora: '#4a4a4a',
        dark: '#464952',
        darkGrey: '#50535C',
        lightGrey: '#626161',
        grey: '#ABABAB',
        grey2: '#828282',
        alto: '#CBCBCB',

        superLightGray: '#c4c4c4',
        light: '#F7F7F7',
        white: '#FFFFFF',
        textBg: '#606266',
        tecnica: '#F09E3D',
        complementaria: '#45C953'
      },
      dark: {
        primary: '#FFFFFF',
        secondary: '#BE7020',
        primaryBack: '#464952',
        secondaryBack: '#50535C',
        // ------------------- de evento ----- //
        accent: '#50B8B1',
        error: '#F44336',
        success: '#4CAF50',
        // ------------------- colores ----- //
        headerColumn: '#E5E5E5',
        subHeaderColumn: '#EFEEEE',
        txtColor: '#717171',
        Preparar: '#D41122',
        Devuelto: '#FF7F27',

        in_production: '#68CD86',
        lightGreen: '#4ECB4A',
        darkBg: '#3D3D3D',
        redBg: '#D41122',
        redBgTitle: '#DE5460',
        redBgBtn: '#E15B67',
        insti: '#32BBED',
        lightInsti: '#5BC9F1',
        superLightInsti: '#84D6F4',
        fucsia: '#EE3D8F',
        mandarine: '#F79420',
        sun: '#FFD100',
        lime: '#D6E031',
        lavander: '#9283BE',
        cherry: '#C2185B',
        tomatoe: '#EF5350',
        orange: '#EF6C00',
        olive: '#6EA100',
        darkMint: '#008388',
        grape: '#6A1B99',
        mint: '#51B8B1',
        mintDarker: '#389A93',
        // ------------------- neutros ----- //
        black: '#000000',
        tundora: '#4a4a4a',
        dark: '#464952',
        darkGrey: '#50535C',
        grey: '#ABABAB',
        grey2: '#828282',
        alto: '#CBCBCB',
        lightGrey: '#626161',
        superLightGray: '#c4c4c4',
        light: '#F7F7F7',
        white: '#FFFFFF',
        textBg: '#606266',

        tecnica: '#F09E3D',
        complementaria: '#45C953'
      }
    },
    options: {
      customProperties: true
    }
  }
}

export default new Vuetify(opts)
