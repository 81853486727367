import { api } from '../config/api'

export const setAsMain = id => api.put(`/product_image/${id}`, { main: true })

export const setImageWepodEndpoint = (id, isCover, isChecked) =>
  api.put(`/product_image/set_image_wepod/${id}`, { isCover, isChecked })

export const uploadImage = body => api.post('/image_url', body)

export const uploadGenericProduct = body => {
  if (body.wepod) {
    return api.post('/product_image_wepod', body)
  }
  return api.post('/product_image', body)
}

export const deleteImage = id => api.delete(`/product_image/${id}`)

export const deleteImageWepod = id => api.delete(`/product_image_wepod/${id}`)

export const getAllImagesFromS3Bucket = () => api.get('/image_url')
