import { api } from '../config/api'

export const getTemplates = ({ sort, limit, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order
      ? `/backoffice/templates?order[${sort.by}]=${sort.order}`
      : '/backoffice/templates'
  return api.get(baseUrl, { params: { ...params, limit } })
}

export const getTemplate = id => api.get(`/backoffice/templates/${id}`)

export const insertTemplate = data => api.post('/backoffice/templates', data)

export const editTemplate = data => api.put(`/backoffice/templates/${data.id}`, data)

export const getKst = id => api.get(`/backoffice/templates/kst/${id}`)

export const disabledEnabledTemplate = id => api.put(`/backoffice/templates/disable-enable/${id}`)
