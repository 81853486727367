export default {
  altDefault: 'Imagen del producto',
  altImageArea: 'Vista previa - Imagen del área',
  btnAddImage: 'Agregar imagen',
  btnAddProduct: 'Agregar producto',
  btnAttachImageProduct: 'Subir imagen comercial',
  btnAttachImageProductWepod: 'Subir imágenes consumidor final',
  btnCancel: 'Cancelar',
  btnChangeImage: 'Cambiar imagen',
  btnDeleteImage: 'Eliminar imagen',
  btnSave: 'Guardar',
  mainFamily: 'Debes seleccionar una categoría principal para continuar',
  confirm: '¿Está seguro de realizar esta acción?',
  confirmEditKit: `Este producto se encuentra en al menos una variante de algún kit. Si continúa se deshabilitarán todas las variantes de estos kits.
  ¿Está seguro de realizar esta acción?`,
  deleteImage: 'Eliminar imagen',
  logisticData: 'Datos logísticos',
  printingAreas: 'Áreas de impresión',
  productImages: 'Imágenes del producto',
  publishedAt: 'Publicado el {date}',
  setAsMain: 'Seleccionar como cabecera',
  zecatCover: 'Portada Zecat',
  wepodCover: 'Portada Wepod',
  complementaryWepod: 'Complementaria Wepod',
  uncheckComplementaryWepod: 'Desmarcar Complementaria',
  toEcommerce: 'Ver en e-commerce',
  variantDetails: 'Variante {id}',
  variantConfig: 'Configuración Setup Máquina',
  variantConfigSubtitle: 'Se puede cargar hasta tres lotes como máximo',
  variantImages: 'Imágenes de la variante {id}',
  variantImageManage: 'Imagenes de la variante {id}',
  variantKey: 'variante',
  variants: 'Variantes',
  withoutVariants: 'Sin variantes',
  withoutPrintingAreas: 'El producto no tiene áreas de impresión definidas',
  variantsFields: {
    active: 'Activo',
    achromatic: 'Acromático',
    attributeSummary: 'Descripción en general',
    elementCode1: 'Código 1',
    elementCode2: 'Código 2',
    elementCode3: 'Código 3',
    generalDescription: 'Descripción',
    id: 'ID',
    images: 'Imágenes',
    reservedStock: 'Stock reservado',
    sku: 'SKU',
    stock: 'Stock',
    color_stock: 'Stock',
    mainVariant: 'Variante principal',
    stockWepod: 'Stock total',
    color: 'Color',
    size: 'Talle'
  },
  printingAreasFields: {
    height: 'Alto',
    images: 'Imágenes',
    width: 'Ancho',
    xCoordinate: 'Posición en X',
    yCoordinate: 'Posición en Y',
    widthCentimeters: 'Ancho en cm.',
    rotation: 'Rotación',
    heightCentimeters: 'Alto en cm.'
  },
  featured: {
    true: 'El producto se agregó a los destacados correctamente',
    false: 'El producto se retiró de los destacados correctamente'
  },
  published: {
    true: 'El producto fue publicado correctamente',
    false: 'El producto fue retirado de los publicados correctamente'
  },
  publishedAlert:
    'Un producto publicado debe tener un precio mayor que 0, cantidad mínima mayor o igual a 1 y una descripción. Si guarda el producto será retirado de los productos publicados',
  familiesAlert: 'Todo producto debe contar con al menos 1 categoría asociada',
  familiesModalMessage:
    'No se pueden eliminar todas las categorías de este producto. Todo producto debe tener al menos 1 categoría asociada',
  familiesConfirmBtn: 'Entendido',
  editWarning:
    '<p>Para poder editar/publicar el producto debe resolver lo siguiente:<br><br><ul>{messageList}</ul></p>',
  errors: {
    required: 'Este campo es requerido',
    notNegative: 'El valor debe ser positivo',
    overMax: 'El valor no puede ser mayor a 100',
    deleteImage: 'Ocurrió un error al eliminar la imagen',
    fileExtension: 'El tipo de archivo de imagen no es permitido',
    notPublishableError: 'Problema al publicar',
    printingAreaImageIdFkey: 'Debe seleccionar una imagen para el área de impresión antes de guardar',
    printingAreaGenericError: 'Ocurrió un error al {action} el área de impresión',
    setImageAsMain: 'Ocurrió un error al seleccionar la imagen como cabecera',
    setImageWepod: 'Ocurrió un error al seleccionar la imagen',
    uploadImageProduct: 'Ocurrió un error al subir la imagen',
    uploadImageVariant: 'Ocurrió un error al asociar la imagen con la variante',
    deleteImageVariantUnable: 'La variante debe estar deshabilitada para eliminar todas sus imágenes',
    validationError: 'Ocurrió un error al guardar los datos',
    notMainImage: 'No se permite publicar productos sin imagen destacada',
    fields: {
      description: '<li><span class="bold">-Descripción</span> no vacía y con máximo 1000 caracteres</li>',
      minimumOrderQuantity: '<li><span class="bold">-Cantidad mínima</span> mayor o igual a 1</li>',
      price: '<li><span class="bold">-Precio</span> mayor a 0</li>',
      tag: '<li><span class="bold">-Tags</span> debe contener máximo 1000 caracteres</li>'
    }
  },
  success: {
    updatedPrintingArea: 'El área de impresión se {action} correctamente',
    uploadImageProduct: 'La imagen se ha subido correctamente',
    uploadImageVariant: 'La imagen se ha subido y fue asociada a la variante correctamente',
    setImageAsMain: 'La imagen se seleccionó como cabecera correctamente',
    setImageWepod: 'La imagen se seleccionó como complementaria correctamente',
    uncheckImageWepod: 'La imagen se desmarcó como complementaria correctamente',
    deleteImage: 'La imagen se eliminó correctamente',
    addSubattributes: 'Los subatributos fueron guardados correctamente'
  },
  failure: {
    addSubattributes: 'Hubo un problema al agregar los subatributos'
  },
  printingAreaActions: {
    add: { success: 'agregó', error: 'agregar' },
    delete: { success: 'eliminó', error: 'eliminar' },
    edit: { success: 'editó', error: 'editar' }
  },
  attributesAndSubattributes: 'Atributos y subatributos',
  subattributes: 'Subatributos',
  wepod: 'Configuración Wepod',
  suggestedProfitPercentage: 'Porcentaje Sugerido de Ganancia',
  minimumProfitPercentage: 'Porcentaje mínimo de ganancia',
  maximumProfitPercentage: 'Porcentaje máximo de ganancia',
  finalConsumerPriceWepod: 'Precio consumidor final',
  printingSetting: 'Configuración de impresión',
  activeWepod: 'Activo Wepod',
  btnAttachDataSheet: 'Subir ficha técnica',
  onlyPdf: '*Subir únicamente formato PDF',
  mandatoryArea: 'Todas las imágenes de cada variante deben tener un área de impresión asociada, de lo contrario no se podrá activar la variante',
  invalidAreaName: 'El nombre del área de impresión no es válido',
  downloadshippingGuidePDF: 'Descargar guía de despacho'
}
