import { format } from 'date-fns'

import { toPrice } from '@/utils/helpers'

export function formatPrice(value) {
  return `${toPrice(value, true)}`
}

export function formatDate(value) {
  return value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : '-'
}

export function formatShortDate(value) {
  return value ? format(new Date(value), 'dd/MM/yyyy') : '-'
}
