import {
  getLogisticOperators,
  getOperatorDetails,
  editOperator,
  createOperator
} from '@/services/LogisticOperatorsService'

export default {
  namespaced: true,

  state: {
    currentPage: 1,
    error: null,
    formErrors: [],
    loading: false,
    sort: {},
    success: null,
    totalRows: null,
    operators: [],
    operatorDetails: {}
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_LOGISTIC_OPERATORS(state, { operators, count, sort }) {
      state.operators = operators
      state.totalRows = count
      state.loading = false
      state.sort = sort
    },
    SET_OPERATOR_DETAILS(state, operator) {
      state.operatorDetails = operator
    },
    SET_OPERATOR_SUCCESS(state) {
      state.success = true
      state.error = null
      state.loading = false
    },
    SET_OPERATOR_ERROR(state, error) {
      state.success = null
      state.error = error
      state.loading = false
    },
    SET_DETAILS_FORM_ERRORS(state, errors) {
      state.formErrors = errors
    }
  },

  actions: {
    resetError({ commit }) {
      commit('SET_STATE_VALUE', { field: 'error', value: null })
    },
    resetFormErrors({ commit }) {
      commit('SET_USER_DETAILS_FORM_ERRORS', [])
    },
    resetSuccess({ commit }) {
      commit('SET_STATE_VALUE', { field: 'success', value: null })
    },
    async getLogisticOperators(
      { commit },
      // eslint-disable-next-line no-magic-numbers
      { page = 1, filters = {}, sort, limit = 10 } = {}
    ) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters, sort, limit }
      const response = await getLogisticOperators(params).catch(error => error.response)
      if (response.data) {
        const { operators, count } = response.data
        commit('SET_LOGISTIC_OPERATORS', { operators, count, sort })
      }
    },
    async getOperatorDetails({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const response = await getOperatorDetails(id)
      if (response.data.operator) {
        commit('SET_OPERATOR_DETAILS', response.data.operator)
        commit('SET_OPERATOR_SUCCESS')
      } else {
        commit('SET_OPERATOR_ERROR')
      }
    },
    async editOperator({ commit }, params) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      try {
        const response = await editOperator(params.id, params.data)
        if (response.data.operator) {
          commit('SET_OPERATOR_DETAILS', response.data.operator)
          commit('SET_OPERATOR_SUCCESS')
        }
      } catch (error) {
        commit('SET_OPERATOR_ERROR', error.response.data.message)
      }
    },
    async createOperator({ commit }, params) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      try {
        const response = await createOperator(params)
        if (response.data.operator) {
          commit('SET_OPERATOR_DETAILS', response.data.operator)
          commit('SET_OPERATOR_SUCCESS')
        }
      } catch (error) {
        commit('SET_OPERATOR_ERROR', error.response.data.message)
      }
    }
  }
}
