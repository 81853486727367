export default {
  title: 'Familias de Productos',
  createFamily: 'Crear Nueva Familia',
  description: 'Nombre',
  highlighted: 'Familia Destacada',
  applyDiscount: 'Aplica Descuento sobre Nivel de Usuario',
  familyKit: 'Familia para kits',
  marketing: 'Url Marketing QR',
  file: 'Archivo',
  path: 'Ruta de Archivo',
  imagen: 'Imagen',
  video: 'Video',
  icon: 'Icon',
  activeIcon: 'Active Icon',
  save: 'Guardar',
  cancel: 'Cancelar',
  filesNotFound: 'No se encontraron archivos',
  successCreate: 'Se ha creado exitosamente',
  errorCreate: 'No se ha podido agregar una nueva familia',
  editFamily: 'Editar Familia',
  saveChanges: 'Guardar Cambios',
  errorEdit: 'No se ha podido editar la familia seleccionada',
  successEdit: 'Se ha editado exitosamente'
}
