import { CamelcaseSerializer } from 'cerealizr'

import { api } from '../config/api'

const serializer = new CamelcaseSerializer()

const serializeBOUserResponse = response => {
  const { total_count: count, page, total_count: totalPages, users } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      BOUsers: users.map(obj => serializer.serialize(obj))
    }
  }
}

export const getBOUsers = params =>
  api.get('/backoffice/users', { params: { ...params, limit: 10 } }).then(serializeBOUserResponse)

export const getBOUserDetails = id =>
  api.get(`/backoffice/users/${id}`).then(response => serializer.serialize(response.data))

export const createBOUser = body =>
  api.post('/backoffice/users', body).then(response => serializer.serialize(response.data))

export const editBOUser = (id, body) =>
  api.put(`/backoffice/users/${id}`, body).then(response => serializer.serialize(response.data))

export const deleteBOUser = id => api.delete(`/backoffice/users/${id}`)
