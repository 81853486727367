export default {
  title: 'Listado de Partners',
  partners: 'Partners',
  fields: {
    businessName: 'Empresa',
    name: 'Nombre',
    cuit: 'RUT',
    address: 'Dirección',
    zipCode: 'Código Postal',
    category: 'Categoría',
    phoneNumber: 'Teléfono',
    active: 'Activo',
    email: 'E-mail',
    state: 'Estado'
  },
  noResultMessage: 'No se encontraron Partners',
  search: 'Buscar',
  cancel: 'Cancelar',
  statePlaceholder: 'Estado',
  categoryPlaceholder: 'Categoría',
  emailPlaceholder: 'E-mail',
  cuitPlaceholder: 'RUT',
  cuitName: 'RUT',
  dniPlaceholder: 'DNI',
  uploadLevels: 'Subir fidelización',
  addPointsUser: 'Agregar puntos',
  successUploadLevel: 'Fidelización actualizada con éxito',
  successUploadPointsUser: 'Se procesaron con éxito {count} registro/s.',
  errorUploadLevel: 'Hubo un error en la carga de csv, pruebe de nuevo mas tarde'
}
