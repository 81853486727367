export default {
  title: 'Reportes',
  addNew: 'Crear reporte',
  editFormTitle: 'Editar reporte',
  noResultMessage: 'No se encontraron reportes',
  id: 'id',
  name: 'Nombre',
  sqlBody: 'Consulta SQL',
  enabled: 'Habilitado',
  back: 'Volver',
  addFormTitle: 'Crear Reporte',
  confirm: 'Confirmar',
  error: {
    required: 'Este campo es requerido',
    forbiddenSentence: 'La Consulta SQL solo debe contener sentencias SELECT',
    report_already_exists_error: 'Ya existe un reporte con ese nombre, elija otro nombre por favor.'
  },
  success: {
    addReport: 'El reporte fue agregado con exito',
    editReport: 'El reporte fue guardado con éxito',
    deleteReport: 'El reporte fue borrado con exito'
  },
  failure: {
    addReport: 'Hubo un error al agregar el reporte',
    editReport: 'Hubo un error al editar el reporte',
    deleteReport: 'Hubo un error al borrar el reporte'
  }
}
