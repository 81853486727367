import { api } from '@/config/api'

export const getDiscounts = params => api.get('/backoffice/discounts', { params: { ...params } })

export const getDiscountsWithVolume = params =>
  api.get('/backoffice/discounts/with_volume', { params: { ...params } })

export const addDiscount = data => api.post('/discounts/create', data)

export const getDiscount = id => api.get(`backoffice/discounts/${id}`)
export const editDiscount = data => api.put(`/backoffice/editDiscount/${data.id}`, data)

export const deleteDiscount = id => api.delete(`/backoffice/deleteDiscount/${id}`, {})

export const getProducts = id => api.get(`backoffice/discounts/products/${id}`)
export const getGenericProducts = () => api.get('backoffice/discounts/generic_products')

export const validateDiscounts = data => api.post('/discounts/validateDiscounts', data)
