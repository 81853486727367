import axios from 'axios'

const headers = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Basic aG9sYUB6ZWNhdGRpZmFwcm8uY29tOlplY2F0ZGlmYXBybzIwMTk='
  }
}

export const putShipping = body => axios.post('https://api.zippin.com.ar/v2/shipments/', body, headers)

export const printShipping = id =>
  axios.get(`https://api.zippin.com.ar/v2/shipments/${id}/documentation?what=label&format=pdf`, headers)
