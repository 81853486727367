import Vue from 'vue'
import VueRouter from 'vue-router'

import { permissions } from '@/config/roles'

const Partners = () => import(/* webpackChunkName: "Partners" */ '../views/Partners')
const Products = () => import(/* webpackChunkName: "Products" */ '../views/Products')
const ProductsWepod = () => import(/* webpackChunkName: "ProductsWepod" */ '../views/ProductsWepod')
const Orders = () => import(/* webpackChunkName: "Orders" */ '../views/Orders')
const ExpressOrders = () => import(/* webpackChunkName: "Orders" */ '../views/ExpressOrders')
const ExpressOrdersDelivery = () => import(/* webpackChunkName: "Orders" */ '../views/ExpressOrdersDelivery')
const Domains = () => import(/* webpackChunkName: "Orders" */ '../views/Domains')
const Techniques = () => import(/* webpackChunkName: "Techniques" */ '../views/Techniques')
const Login = () => import(/* webpackChunkName: "Login" */ '../views/Login')
const UsersDetails = () => import(/* webpackChunkName: "UsersDetails" */ '../views/UsersDetails')
const ProductsDetails = () => import(/* webpackChunkName: "ProductsDetails" */ '../views/ProductsDetails')
const ProductsDetailsWepod = () =>
  import(/* webpackChunkName: "ProductsDetails" */ '../views/ProductsDetailsWepod')
const OrderDetails = () => import(/* webpackChunkName: "OrderDetails" */ '../views/OrderDetails')
const NewTechnique = () => import(/* webpackChunkName: "NewTechnique" */ '../views/NewTechnique')
const TechniqueDetails = () => import(/* webpackChunkName: "TechniqueDetails" */ '../views/TechniqueDetails')
const Reservations = () => import(/* webpackChunkName: "Reservations" */ '../views/Reservations')
const Configs = () => import(/* webpackChunkName: "Configs" */ '../views/Configs')
const Holidays = () => import(/* webpackChunkName: "Holidays" */ '../views/Holidays')
const Catalogs = () => import(/* webpackChunkName: "Catalogs" */ '../views/Catalogs/Catalogs')
const AddCatalog = () => import(/* webpackChunkName: "AddCatalog" */ '../views/Catalogs/views/AddCatalog')
const EditCatalog = () => import(/* webpackChunkName: "EditCatalog" */ '../views/Catalogs/views/EditCatalog')
const Attributes = () => import(/* webpackChunkName: "Attributes" */ '../views/Attributes/Attributes')
const AddAttribute = () =>
  import(/* webpackChunkName: "AddAttribute" */ '../views/Attributes/views/AddAttribute')
const EditAttribute = () =>
  import(/* webpackChunkName: "EditAttribute" */ '../views/Attributes/views/EditAttribute')
const Roles = () => import(/* webpackChunkName: "Roles" */ '../views/Roles')
const BOUsers = () => import(/* webpackChunkName: "BOUsers" */ '../views/BOUsers')
const newBOUser = () => import(/* webpackChunkName: "newBOUser" */ '../views/newBOUser')
const BOUserDetails = () => import(/* webpackChunkName: "BOUserDetails" */ '../views/BOUserDetails')
const Advances = () => import(/* webpackChunkName: "Advances" */ '../views/Advances')
const AdvanceDetails = () => import(/* webpackChunkName: "AdvanceDetails" */ '../views/AdvanceDetails')
const Complaints = () => import(/* webpackChunkName: "Complaints" */ '../views/Complaints')
const ComplaintDetails = () => import(/* webpackChunkName: "ComplaintDetails" */ '../views/ComplaintDetails')
const ProductFamilies = () => import(/* webpackChunkName: "ProductFamilies" */ '../views/ProductFamilies')
const Reports = () => import(/* webpackChunkName: "Reports" */ '../views/Reports/Reports')
const AddReport = () => import(/* webpackChunkName: "AddReport" */ '../views/Reports/views/AddReport')
const EditReport = () => import(/* webpackChunkName: "EditReport" */ '../views/Reports/views/EditReport')
const Kits = () => import(/* webpackChunkName: "Kits" */ '../views/Kits/Kits')
const KitsDetail = () => import(/* webpackChunkName: "Kits" */ '../views/Kits/views/KitsDetail')
const Discounts = () => import(/* webpackChunkName: "Discounts" */ '../views/Discounts/Discounts')
const EditDiscount = () =>
  import(/* webpackChunkName: "EditDiscounts" */ '../views/Discounts/views/EditDiscount')
const EditDiscountByQuantity = () =>
  import(/* webpackChunkName: "EditDiscounts" */ '../views/Discounts/views/EditDiscountQuantity')
const AddDiscountByQuantity = () =>
  import(/* webpackChunkName: "EditDiscounts" */ '../views/Discounts/views/AddDiscountQuantity')

const AddDiscount = () => import(/* webpackChunkName: "AddDiscount" */ '../views/Discounts/views/AddDiscount')
const PrintingProfiles = () =>
  import(/* webpackChunkName: "Orders" */ '../views/PrintingProfiles/PrintingProfiles')
const AddPrintingProfile = () =>
  import(/* webpackChunkName: "AddDiscount" */ '../views/PrintingProfiles/views/AddPrintingProfile')
const EditPrintingProfile = () =>
  import(/* webpackChunkName: "AddDiscount" */ '../views/PrintingProfiles/views/EditPrintingProfile')
const ViewPrintingProfile = () =>
  import(/* webpackChunkName: "AddDiscount" */ '../views/PrintingProfiles/views/ViewPrintingProfile')
const logisticOperators = () =>
  import(/* webpackChunkName: "LogisticOperators" */ '../views/LogisticOperators/LogisticOperators')
const OperatorDetails = () =>
  import(/* webpackChunkName: "EditOperator" */ '../views/LogisticOperators/views/EditOperator')
Vue.use(VueRouter)

export const routes = [
  'configs',
  'default',
  'login',
  'orders',
  'expressorders',
  'expressordersdelivery',
  'products',
  'productsWepod',
  'productDetails',
  'productDetailsWepod',
  'partners',
  'userDetails',
  'userManagenent',
  'orderDetails',
  'reservations',
  'techniques',
  'newTechnique',
  'techniqueDetails',
  'catalogs',
  'editCatalog',
  'addCatalog',
  'editCatalog',
  'attributes',
  'addAttribute',
  'editAttribute',
  'roles',
  'BOUsers',
  'newBOUser',
  'BOUserDetails',
  'holidays',
  'advances',
  'advanceDetails',
  'complaints',
  'complaintDetails',
  'productFamilies',
  'reports',
  'domains',
  'addReport',
  'editReport',
  'kits',
  'kitsDetail',
  'kitsAdd',
  'discounts',
  'addDiscount',
  'editDiscount',
  'printingProfiles',
  'addPrintingProfile',
  'editPrintingProfile',
  'viewPrintingProfile',
  'editDiscountByQuantity',
  'addDiscountByQuantity',
  'logisticOperators',
  'operatorDetails',
  'addOperator'
].reduce((acumulator, elem) => ({ ...acumulator, [elem]: elem }), {})

export const menuLinks = [
  {
    name: routes.partners,
    path: '/partners',
    component: Partners,
    icon: 'el-icon-user',
    meta: { isPrivate: true, permission: permissions.partnerView }
  },
  {
    name: routes.products,
    path: '/products',
    component: Products,
    icon: 'el-icon-files',
    meta: { isPrivate: true, permission: permissions.productView }
  },
  {
    name: routes.productsWepod,
    path: '/products-wepod',
    component: ProductsWepod,
    icon: 'el-icon-files',
    meta: { isPrivate: true, permission: permissions.productWepodView }
  },
  {
    name: routes.kits,
    path: '/kits',
    component: Kits,
    icon: 'el-icon-files',
    meta: { isPrivate: true, permission: permissions.kitsView }
  },
  {
    name: routes.techniques,
    path: '/techniques',
    component: Techniques,
    icon: 'el-icon-picture-outline',
    meta: { isPrivate: true, permission: permissions.printingTypeView }
  },
  {
    name: routes.attributes,
    path: '/attributes',
    component: Attributes,
    icon: 'el-icon-takeaway-box',
    meta: { isPrivate: true, permission: permissions.attributesView }
  },
  {
    name: routes.catalogs,
    path: '/catalogs',
    component: Catalogs,
    icon: 'el-icon-notebook-2',
    meta: { isPrivate: true, permission: permissions.catalogsView }
  },
  {
    name: routes.orders,
    path: '/orders',
    component: Orders,
    icon: 'el-icon-sell',
    meta: { isPrivate: true, permission: permissions.orderView }
  },
  {
    name: routes.expressorders,
    path: '/expressorders',
    component: ExpressOrders,
    icon: 'el-icon-sell',
    meta: { isPrivate: true, permission: permissions.viewSimpleSamplePreparation }
  },
  {
    name: routes.expressordersdelivery,
    path: '/express-orders-delivery',
    component: ExpressOrdersDelivery,
    icon: 'el-icon-sell',
    meta: { isPrivate: true, permission: permissions.viewSimpleSampleDistribution }
  },
  {
    name: routes.complaints,
    path: '/complaints',
    component: Complaints,
    icon: 'el-icon-warning-outline',
    meta: { isPrivate: true, permission: permissions.complaintsView }
  },
  {
    name: routes.advances,
    path: '/advances',
    component: Advances,
    icon: 'el-icon-phone',
    meta: { isPrivate: true, permission: permissions.advancesView }
  },
  {
    name: routes.reservations,
    path: '/reservations',
    component: Reservations,
    icon: 'el-icon-box',
    meta: { isPrivate: true, permission: permissions.reservationView }
  },
  {
    name: routes.discounts,
    path: '/discounts',
    component: Discounts,
    icon: 'el-icon-sold-out',
    meta: { isPrivate: true, permission: permissions.viewDiscounts }
  },
  {
    name: routes.roles,
    path: '/roles',
    component: Roles,
    icon: 'el-icon-unlock',
    meta: { isPrivate: true, permission: permissions.rolesView }
  },
  {
    name: routes.BOUsers,
    path: '/users-backoffice',
    component: BOUsers,
    icon: 'el-icon-view',
    meta: { isPrivate: true, permission: permissions.usersView }
  },
  {
    name: routes.printingProfiles,
    path: '/printing-profiles',
    component: PrintingProfiles,
    icon: 'el-icon-printer',
    meta: { isPrivate: true, permission: permissions.printingProfiles }
  },

  {
    name: routes.configs,
    path: '/configs',
    component: Configs,
    icon: 'el-icon-setting',
    meta: { isPrivate: true, permission: permissions.configView }
  },
  {
    name: routes.holidays,
    path: '/holidays',
    component: Holidays,
    icon: 'el-icon-date',
    meta: { isPrivate: true, permission: permissions.holidaysView }
  },
  {
    name: routes.productFamilies,
    path: '/product-families',
    component: ProductFamilies,
    icon: 'el-icon-files',
    meta: { isPrivate: true, permission: permissions.productFamilies }
  },
  {
    name: routes.logisticOperators,
    path: '/logistic-operators',
    component: logisticOperators,
    icon: 'el-icon-location',
    meta: { isPrivate: true, permission: permissions.logisticOperators }
  },
  {
    name: routes.domains,
    path: '/domains',
    component: Domains,
    icon: 'el-icon-setting',
    meta: { isPrivate: true, permission: permissions.manageDomains }
  },
  {
    name: routes.reports,
    path: '/reports',
    component: Reports,
    icon: 'el-icon-document',
    meta: { isPrivate: true, permission: permissions.reportsView }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: routes.default,
      path: '/',
      redirect: { name: routes.partners },
      meta: { isPrivate: true, permission: permissions.partnerView }
    },
    {
      name: routes.login,
      path: '/login',
      component: Login
    },
    {
      name: routes.userDetails,
      path: '/users/:id',
      component: UsersDetails,
      meta: { isPrivate: true, permission: permissions.partnerView }
    },
    {
      name: routes.productDetails,
      path: '/products/:id',
      component: ProductsDetails,
      meta: { isPrivate: true, permission: permissions.productDetailsView }
    },
    {
      name: routes.productDetailsWepod,
      path: '/products-wepod/:id',
      component: ProductsDetailsWepod,
      meta: { isPrivate: true, permission: permissions.productWepodView }
    },
    {
      name: routes.newTechnique,
      path: '/techniques/new',
      component: NewTechnique,
      meta: { isPrivate: true, permission: permissions.printingTypeEdit }
    },
    {
      name: routes.techniqueDetails,
      path: '/techniques/:id',
      component: TechniqueDetails,
      meta: { isPrivate: true, permission: permissions.printingTypeView }
    },
    {
      name: routes.orderDetails,
      path: '/orders/:id',
      component: OrderDetails,
      meta: { isPrivate: true, permission: permissions.orderView }
    },
    {
      name: routes.addCatalog,
      path: '/catalog/new-catalog',
      component: AddCatalog,
      meta: { isPrivate: true, permission: permissions.catalogsView }
    },
    {
      name: routes.editCatalog,
      path: '/catalog/:id',
      component: EditCatalog,
      meta: { isPrivate: true, permission: permissions.catalogsView }
    },
    {
      name: routes.addPrintingProfile,
      path: '/printing-profiles/new-printing-profile/:id',
      component: AddPrintingProfile,
      meta: { isPrivate: true, permission: permissions.printingProfiles }
    },
    {
      name: routes.editPrintingProfile,
      path: '/printing-profiles/:id',
      component: EditPrintingProfile,
      meta: { isPrivate: true, permission: permissions.printingProfiles }
    },
    {
      name: routes.viewPrintingProfile,
      path: '/printing-profiles/view/:id',
      component: ViewPrintingProfile,
      meta: { isPrivate: true, permission: permissions.printingProfiles }
    },

    {
      name: routes.newBOUser,
      path: '/users-backoffice/new',
      component: newBOUser,
      meta: { isPrivate: true, permission: permissions.usersEdit }
    },
    {
      name: routes.BOUserDetails,
      path: '/users-backoffice/:id',
      component: BOUserDetails,
      meta: { isPrivate: true, permission: permissions.usersView }
    },
    {
      name: routes.addAttribute,
      path: '/attributes/new-attribute',
      component: AddAttribute,
      meta: { isPrivate: true, permission: permissions.attributesView }
    },
    {
      name: routes.editAttribute,
      path: '/attributes/:id',
      component: EditAttribute,
      meta: { isPrivate: true, permission: permissions.attributesView }
    },
    {
      name: routes.advanceDetails,
      path: '/advances/:id',
      component: AdvanceDetails,
      meta: { isPrivate: true, permission: permissions.advancesView }
    },
    {
      name: routes.complaintDetails,
      path: '/complaint/:id',
      component: ComplaintDetails,
      meta: { isPrivate: true, permission: permissions.complaintsView }
    },
    {
      name: routes.addReport,
      path: '/reports/new',
      component: AddReport,
      meta: { isPrivate: true, permission: permissions.editReport }
    },
    {
      name: routes.editReport,
      path: '/reports/:id',
      component: EditReport,
      meta: { isPrivate: true, permission: permissions.reportsView }
    },
    {
      name: routes.kitsDetail,
      path: '/kits/:id',
      component: KitsDetail,
      meta: { isPrivate: true, permission: permissions.kitsView }
    },
    {
      name: routes.kitsAdd,
      path: '/kits/add-new-kit',
      component: KitsDetail,
      meta: { isPrivate: true, permission: permissions.kitsView }
    },
    {
      name: routes.addDiscount,
      path: '/discounts/new-discount',
      component: AddDiscount,
      meta: { isPrivate: true, permission: permissions.viewDiscounts }
    },
    {
      name: routes.editDiscount,
      path: '/discounts/:id',
      component: EditDiscount,
      meta: { isPrivate: true, permission: permissions.viewDiscounts }
    },
    {
      name: routes.addDiscountByQuantity,
      path: '/discounts-by-quantity/new-discount',
      component: AddDiscountByQuantity,
      meta: { isPrivate: true, permission: permissions.viewDiscounts }
    },
    {
      name: routes.editDiscountByQuantity,
      path: '/discounts-by-quantity/:id',
      component: EditDiscountByQuantity,
      meta: { isPrivate: true, permission: permissions.viewDiscounts }
    },
    {
      name: routes.operatorDetails,
      path: '/logistic-operators/:id',
      component: OperatorDetails,
      meta: { isPrivate: true, permission: permissions.logisticOperators }
    },
    {
      name: routes.addOperator,
      path: '/logistic-operators/new-operator',
      component: OperatorDetails,
      meta: { isPrivate: true, permission: permissions.editLogisticOperators }
    },

    ...menuLinks
  ]
})

export default router
