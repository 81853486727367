import { mapState } from 'vuex'

import { checkPermission, permissions } from '@/config/roles'

export default {
  data() {
    return { permissions }
  },
  computed: {
    ...mapState({
      currentUser: state => state.auth.currentUser
    })
  },
  methods: {
    checkPermission(permission) {
      return checkPermission(this.currentUser.permissions, permission)
    }
  }
}
