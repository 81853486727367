import { api } from '../config/api'

const serializeReportsResponse = response => {
  const { status, statusText, data } = response
  const { reports, total_pages: totalPages, current_page: currentPage, total_count: totalCount, count } = data
  return {
    status,
    statusText,
    data: {
      count,
      totalCount,
      totalPages,
      currentPage,
      reports
    }
  }
}

export const getReports = ({ page }) =>
  api
    .get('/backoffice/reports', {
      params: { page, pageSize: 10, limit: 10 }
    })
    .then(serializeReportsResponse)

export const getReport = id => api.get(`backoffice/reports/${id}`)

export const addReport = ({ report }) => api.post('/backoffice/reports', { report })

export const editReport = ({ report }) => api.put(`/backoffice/reports/${report.id}`, { report })

export const downloadReport = (id, filename) => {
  const baseUrl = `/backoffice/reports/${id}/download`
  return api({
    method: 'get',
    url: baseUrl,
    responseType: 'blob'
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', filename)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}
