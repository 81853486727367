import { CamelcaseSerializer } from 'cerealizr'

import { api } from '../config/api'

const serializer = new CamelcaseSerializer()

const serializeTechniquesResponse = response => {
  const { total_count: count, page, total_count: totalPages, printing_types: printingTypes } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      printingTypes: printingTypes.map(obj => serializer.serialize(obj))
    }
  }
}

export const getTechniques = params =>
  api
    .get('/backoffice/printing_types', { params: { ...params, limit: 10 } })
    .then(serializeTechniquesResponse)

export const getTechniquesConfig = () => api.get('/printing_type/config')

export const getTechniquesMinConfig = () => api.get('/backoffice/printing_types/minConfig')

export const editTechniquesConfig = body =>
  api.put('/backoffice/printing_types/minConfig', body).then(response => response.data)

export const getTechniqueDetails = id =>
  api.get(`/printing_type/${id}`).then(response => serializer.serialize(response.data))

export const createTechnique = body =>
  api.post('/printing_type', body).then(response => serializer.serialize(response.data))

export const editTechnique = (id, body) =>
  api.put(`/printing_type/${id}`, body).then(response => serializer.serialize(response.data))

export const toggleEnableTechnique = id =>
  api.post(`/printing_type/${id}`).then(response => serializer.serialize(response.data))
