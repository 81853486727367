import orderby from 'lodash.orderby'

const defaultProtocol = 'https://'
const decimalRoundPrecision = process.env.VUE_APP_DECIMAL_ROUND_PRECISION

export function isGreaterThan(val1, val2) {
  if (val1 > val2) {
    return 1
  }
  if (val1 < val2) {
    return -1
  }
  return 0
}

export function orderTrueFirst(val1, val2) {
  if (val1 === val2) return 0
  return val1 ? -1 : 1
}

export function isUrlValid(url) {
  const pattern = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
  if (pattern.test(url)) return true
  return false
}

export function formatImageUrl(imageUrl) {
  let imageUrlReturn = imageUrl
  if (!isUrlValid(imageUrl)) imageUrlReturn = `${defaultProtocol}${imageUrlReturn}`
  return imageUrlReturn
}

export function toPrice(value, withSymbol = false, withCode = false) {
  const formatter = new Intl.NumberFormat(`${process.env.VUE_APP_COUNTRY_LOCALE}`, {
    style: 'decimal',
    minimumFractionDigits: decimalRoundPrecision,
    maximumFractionDigits: decimalRoundPrecision
  })
  return (
    (withCode ? `${process.env.VUE_APP_COUNTRY_CURRENCY_CODE} ` : '') +
    (withSymbol ? `${process.env.VUE_APP_COUNTRY_CURRENCY_SYMBOL} ` : '') +
    (value ? formatter.format(value) : 0)
  )
}

export function getApplicationTypes(printingAreas) {
  let areaIndex = 0
  const namesShort = []
  const namesLarge = []
  const orderedPrintingTypes = orderby(printingAreas, 'area_index')
  orderedPrintingTypes.forEach((pArea, index) => {
    const name = pArea.printingType ? pArea.printingType.name : ''
    if (name) {
      areaIndex = index
      const nameArr = name.split(' ')
      const subsLength = nameArr.length > 1 ? 1 : 2
      namesLarge.push(name)
      namesShort.push(
        nameArr
          .map(n => n.substring(0, subsLength))
          .join('')
          .toUpperCase()
      )
    }
  })
  const uniqueNamesQuantity = namesShort.filter((pt, idx, arr) => arr.indexOf(pt) === idx).length
  const nameIndex = printingAreas[areaIndex] ? printingAreas[areaIndex].printingType.name : '-'
  return uniqueNamesQuantity > 1 ? namesLarge.join(' / ') : nameIndex
}

export function groupAndCheckActive(arr) {
  const groups = arr.reduce((result, item) => {
    const { name, active, wepod } = item

    if (!result[name]) {
      result[name] = { ...item, hasTrue: false }
    }

    result[name].hasTrue = result[name].hasTrue || active
    result[name].hasTrueWepod = result[name].hasTrueWepod || wepod

    return result
  }, {})

  const groupedArray = Object.values(groups)
  groupedArray.forEach(group => {
    group.allFalse = !group.hasTrue
    group.allFalseWepod = !group.hasTrueWepod
  })

  return groupedArray
}

export function cleanFileName(file) {
  // Dividir el nombre del archivo en nombre y extensión
  const fileNameSplit = file.name.split('.');
  const extension = fileNameSplit.pop();
  const cleanName = fileNameSplit.join().replace(/[^a-zA-Z0-9-_]/g, '-');

  // Crear un nuevo archivo con el nombre limpio
  const cleanedFile = new File([file], `${cleanName}.${extension}`, {
      type: file.type,
      lastModified: file.lastModified
  });

  return cleanedFile;
}

