import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ar from '../locales/es-AR/index'
import cl from '../locales/es-CL/index'

Vue.use(VueI18n)

export default new VueI18n({
  locale: process.env.VUE_APP_COUNTRY_ID || 'ar',
  fallbackLocale: process.env.VUE_APP_COUNTRY_ID || 'ar',
  messages: { ar, cl }
})
