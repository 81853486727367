import { CamelcaseSerializer } from 'cerealizr'

import { api } from '../config/api'

const serializer = new CamelcaseSerializer()

const serializeOperators = response => {
  const { count, page, total_pages: totalPages, logisticOperators } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      operators: logisticOperators.map(obj => serializer.serialize(obj))
    }
  }
}

// eslint-disable-next-line no-magic-numbers
export const getLogisticOperators = ({ sort, limit = 10, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order
      ? `backoffice/logistic_operators?order[${sort.by}]=${sort.order}`
      : 'backoffice/logistic_operators'
  return api.get(baseUrl, { params: { ...params, limit } }).then(operators => serializeOperators(operators))
}

// CUIDADO, hay que cambiar la cantidad de límites para que sea dinámico

export const getOperatorDetails = id =>
  api.get(`backoffice/logistic_operators/${id}`).then(response => response)

export const editOperator = (id, params) =>
  api.put(`backoffice/logistic_operators/${id}`, { operator: params }).then(response => response)

export const createOperator = params =>
  api.post('backoffice/logistic_operators', { operator: params }).then(response => response)
