import { CamelcaseSerializer } from 'cerealizr'

import { fieldsToSnakeCase } from '@/utils/api'

import { api } from '../config/api'

const serializer = new CamelcaseSerializer()

const serializeComplaintResponse = response => {
  const { total_count: count, page, total_count: totalPages, complaints } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      complaints: complaints.map(obj => serializer.serialize(obj))
    }
  }
}

export const getComplaints = params =>
  api.get('/complaints', { params: { ...params, limit: 6 } }).then(serializeComplaintResponse)

export const getComplaintDetails = id =>
  api.get(`/complaint/${id}`).then(response => serializer.serialize(response.data))

export const editComplaint = (id, complaint) => api.put(`/complaint/${id}`, fieldsToSnakeCase(complaint))
