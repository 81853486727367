export default {
  default: 'El dato no es válido',
  between: 'Debe estar entre {min} y {max}',
  greaterThan: 'Debe ser mayor que {min}',
  smallerThan: 'Debe ser menor que {max}',
  maxLength: 'Máximo {max} caracteres',
  minLength: 'Mínimo {min} caracteres',
  required: 'Requerido',
  arrayRequired: 'Debe haber mínimo un elemento seleccionado',
  invalidCuit: 'El RUT es inválido',
  whiteSpaces: 'No se permiten espacios en blanco',
  phoneNumber: 'El número ingresado no es válido',
  validDecimals: 'Se superó el numero máximo de decimales',
  numeric: 'Solo se permiten valores numéricos'
}
