import orderBy from 'lodash.orderby'

import { addRole, deleteRole, editRole, getRoles } from '@/services/RolesService'

const cloneObject = obj => JSON.parse(JSON.stringify(obj))

export default {
  namespaced: true,

  state: {
    loading: false,
    roles: [],
    cacheRoles: [],
    permissions: [],
    addRoleLoading: false,
    deleteRoleLoading: false,
    editRoleLoading: false
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_ROLES_AND_PERMISSIONS(state, roles) {
      state.roles = roles
      state.cacheRoles = cloneObject(state.roles)
      state.permissions = roles.length
        ? orderBy(roles[0].permissions, 'name').map(permission => ({ ...permission, enabled: false }))
        : []
    }
  },

  actions: {
    getRoles({ commit }, onSuccess) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getRoles()
        .then(response => {
          commit('SET_ROLES_AND_PERMISSIONS', response.data?.roles)
          if (onSuccess) onSuccess()
        })
        .finally(() => commit('SET_STATE_VALUE', { field: 'loading', value: false }))
    },
    addRole({ commit, dispatch }, { name, onSuccess, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'addRoleLoading', value: true })
      addRole({ name })
        .then(
          response => dispatch('getRoles', () => onSuccess(response?.data?.id)),
          () => onFailure()
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'addRoleLoading', value: false }))
    },
    deleteRole({ state, commit }, { roleIndex, onSuccess, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'deleteRoleLoading', value: true })
      deleteRole(state.roles[roleIndex].id)
        .then(
          () => {
            const updatedRoles = [...state.roles]
            updatedRoles.splice(roleIndex, 1)
            commit('SET_STATE_VALUE', { field: 'roles', value: updatedRoles })
            onSuccess()
          },
          ({ response }) => onFailure(response.data)
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'deleteRoleLoading', value: false }))
    },
    editRole({ state, commit, dispatch }, { roleIndex, onFinish }) {
      commit('SET_STATE_VALUE', { field: 'editRoleLoading', value: true })
      const role = state.roles[roleIndex]
      editRole(role.id, { permissions: role.permissions })
        .then(
          () => dispatch('getRoles', () => onFinish(true)),
          () => onFinish()
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'editRoleLoading', value: false }))
    },
    togglePermission({ state, commit }, { roleIndex, permissionId }) {
      const updatedRoles = cloneObject(state.roles)
      const objPermisiion = updatedRoles[roleIndex].permissions.find(
        permission => permission.id === permissionId
      )
      objPermisiion.enabled = !objPermisiion.enabled
      commit('SET_STATE_VALUE', { field: 'roles', value: updatedRoles })
    },
    cancelChanges({ state, commit }, roleIndex) {
      const restoreRoles = cloneObject(state.roles)
      restoreRoles[roleIndex].permissions = cloneObject(state.cacheRoles[roleIndex].permissions)
      commit('SET_STATE_VALUE', { field: 'roles', value: restoreRoles })
    }
  }
}
