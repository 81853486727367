export default {
  addFormTitle: 'Crear catálogo',
  editFormTitle: 'Editar catálogo',
  back: 'Volver',
  selectFile: 'Seleccionar archivo',
  confirm: 'Confirmar',
  name: 'Nombre',
  file: 'Archivo',
  enabled: 'Habilitado',
  save: 'Guardar',
  cancel: 'Resetear',
  success: {
    editCatalog: 'Los cambios fueron guardados',
    addCatalog: 'El catálogo fue agregado'
  },
  error: {
    addCatalog: 'Hubo un error al agregar el catálogo',
    editCatalog: 'Hubo un error al guardar los cambios',
    uploadFile: 'Hubo un error al querer firmar la url del catálogo',
    awsUploadFile: 'Hubo un error al querer guardar el catálogo',
    required: 'Campo requerido'
  }
}
