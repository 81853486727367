import {
  getTemplates,
  getTemplate,
  insertTemplate,
  editTemplate,
  getKst,
  disabledEnabledTemplate
} from '@/services/TemplatesService'
import { isError } from '@/utils/api'
import { dowloadXml } from '@/utils/files'

const ID_FUNC = x => x
export default {
  namespaced: true,

  state: {
    loading: false,
    isLoaded: false,
    loadingOverlay: false,
    currentPage: 1,
    count: 0,
    templates: [],
    sort: {},
    totalRows: null,
    template: null,
    customTemplates: [],
    defaultTemplates: [],
    error: null,
    cancelError: false,
    cancelSuccess: false,
    hasNextPage: false,
    page: 1,
    totalPages: 1
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_TEMPLATES(state, { templates, count, sort, hasNextPage, totalPages }) {
      state.templates = templates
      state.totalRows = count
      state.loading = false
      state.sort = sort
      if (hasNextPage) {
        state.hasNextPage = hasNextPage
      }
      if (totalPages) {
        state.totalPages = totalPages
      }
    },
    SET_TEMPLATE(state, template) {
      state.template = template
    },
    SET_CUSTOM_TEMPLATES(state, templates) {
      state.customTemplates = templates
    },
    SET_DEFAULT_TEMPLATES(state, templates) {
      state.defaultTemplates = templates
    }
  },

  actions: {
    // eslint-disable-next-line no-magic-numbers
    async getTemplates({ commit }, { page = 1, filters = {}, sort, limit = 10 }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters, sort, limit }
      const response = await getTemplates(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_TEMPLATES', { templates: [], count: 0 })
      } else {
        const { rows, count } = response.data
        commit('SET_TEMPLATES', { templates: rows, count })
        commit('SET_STATE_VALUE', { field: 'error', value: null })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    async getGenericTemplate({ commit }, { id }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const { data } = await getTemplate(id)
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
      return data
    },
    getTemplate({ commit }, { id, onSuccess = ID_FUNC, onFailure = ID_FUNC }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getTemplate(id).then(
        response => {
          const { data } = response
          commit('SET_STATE_VALUE', { field: 'error', value: null })
          commit('SET_TEMPLATE', data)
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onSuccess('getTemplate')
        },
        ({ response }) => {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { type: 'getTemplate', status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          onFailure('getTemplate', status, statusText)
        }
      )
    },
    async getDefaultTemplates({ commit }, { page = 1, sort }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, base: true, sort, limit: 1000 }
      const response = await getTemplates(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_DEFAULT_TEMPLATES', [])
      } else {
        const { rows } = response.data
        commit(
          'SET_DEFAULT_TEMPLATES',
          rows.map(e => ({ name: e.name, id: e.id }))
        )
        commit('SET_STATE_VALUE', { field: 'error', value: null })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    async getCustomTemplates({ commit }, { page = 1, sort }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, base: false, sort, limit: 1000 }
      const response = await getTemplates(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_CUSTOM_TEMPLATES', [])
      } else {
        const { rows } = response.data
        commit(
          'SET_CUSTOM_TEMPLATES',
          rows.map(e => ({ name: e.name, id: e.id }))
        )
        commit('SET_STATE_VALUE', { field: 'error', value: null })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },

    async insertTemplate({ commit }, data) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })

      try {
        const response = await insertTemplate(data)
        if (isError(response.status)) {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          return false
        }
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
        return response.data
      } catch {
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      }
      return false
    },

    async editTemplate({ commit }, data) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })

      try {
        const response = await editTemplate(data)
        if (isError(response.status)) {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          return false
        }
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
        return response.data
      } catch {
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      }
      return false
    },
    async dowloadKst({ commit }, data) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      try {
        const response = await getKst(data.id)
        if (isError(response.status)) {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          return false
        }
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })

        const { kstFile, fileName } = response.data
        dowloadXml(fileName, kstFile)
        return true
      } catch {
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      }
      return false
    },

    async disabledEnabledTemplate({ commit }, data) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      try {
        const response = await disabledEnabledTemplate(data.id)
        if (isError(response.status)) {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
          return false
        }
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_STATE_VALUE', { field: 'loading', value: false })

        return true
      } catch {
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      }
      return false
    },

    resetValue({ commit }, field) {
      commit('SET_STATE_VALUE', { field, value: null })
    }
  }
}
