/* eslint-disable camelcase */
import { getReservations, cancelReservation, reserve } from '@/services/ReservationService'
import { isError } from '@/utils/api'

export default {
  namespaced: true,

  state: {
    currentPage: 1,
    error: null,
    errorCancelReservation: false,
    loading: false,
    loadingCancelReservation: false,
    loadingReserve: false,
    successCancelReservation: false,
    reservations: [],
    totalRows: null,
    sort: {}
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_ORDERS(state, { reservations, count, sort }) {
      state.reservations = reservations
      state.totalRows = count
      state.loading = false
      state.sort = sort
    }
  },

  actions: {
    async getReservations({ commit }, { page = 1, filters = {}, sort } = {}) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = {
        page,
        ...filters,
        sortBy: sort?.by ? sort.by : null,
        sortOrder: sort?.order ? sort.order : null,
        limit: 10
      }
      const response = await getReservations(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_ORDERS', { reservations: [], count: 0, sort: {} })
      } else {
        const { reservations, count } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_ORDERS', { reservations, count, sort })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    cancelReservation({ commit, dispatch, state }, { id, filters }) {
      commit('SET_STATE_VALUE', { field: 'loadingCancelReservation', value: true })
      cancelReservation(id).then(
        () => {
          dispatch('getReservations', { page: state.currentPage, filters })
          commit('SET_STATE_VALUE', { field: 'loadingCancelReservation', value: false })
          commit('SET_STATE_VALUE', { field: 'errorCancelReservation', value: false })
          commit('SET_STATE_VALUE', { field: 'successCancelReservation', value: true })
        },
        ({ response }) => {
          commit('SET_STATE_VALUE', { field: 'loadingCancelReservation', value: false })
          commit('SET_STATE_VALUE', { field: 'errorCancelReservation', value: response.data })
          commit('SET_STATE_VALUE', { field: 'successCancelReservation', value: false })
        }
      )
    },
    reserve({ commit, dispatch, state }, { body, filters, onSuccess, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loadingReserve', value: true })
      reserve(body)
        .then(
          () => {
            dispatch('getReservations', { page: state.currentPage, filters })
            onSuccess()
          },
          ({ response }) => onFailure(response.data)
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loadingReserve', value: false }))
    },
    resetValue({ commit }, field) {
      commit('SET_STATE_VALUE', { field, value: null })
    }
  }
}
