/* eslint-disable no-unused-vars */
import {
  getAllKits,
  addKit,
  getKit,
  editKit,
  editVariantPublished,
  getKitsByExternalId,
  getKitFamilies
} from '@/services/KitService'
import { isError } from '@/utils/api'
// eslint-disable-next-line no-unused-vars
import { uploadImage } from '@/services/ImageService'
// eslint-disable-next-line no-unused-vars
import { uploadImageAWS } from '@/services/s3Service'

export default {
  namespaced: true,

  state: {
    kits: [],
    loadingKits: false,
    loadingKit: false,
    loadingAddImage: false,
    currentPage: 1,
    sort: {},
    kitFamilies: [],
    images: [],
    isEdit: false,
    count: 0
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_NEW_IMAGE(state, { value }) {
      state.images.push({ image: value })
    },
    RESET_IMAGES(state) {
      state.images = []
    },
    SET_KIT_FAMILIES(state, value) {
      state.kitFamilies = value
    }
  },

  actions: {
    async getKits({ commit }, { page = 1, filters = {}, sort }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'sort', value: sort })
      commit('SET_STATE_VALUE', { field: 'loadingKits', value: true })
      const params = { page, ...filters, sort, viewType: 'BO' }
      const response = await getAllKits(params)
      if (isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'kits', value: [] })
        commit('SET_STATE_VALUE', { field: 'count', value: 0 })
      } else {
        commit('SET_STATE_VALUE', { field: 'kits', value: response.data.kits })
        commit('SET_STATE_VALUE', { field: 'count', value: response.data.count })
      }
      commit('SET_STATE_VALUE', { field: 'loadingKits', value: false })
    },
    async getKitByName({ commit }, { filters = {} }) {
      const { data } = await getAllKits(filters)
      return data?.kits[0]
    },
    async addKit({ commit }, params) {
      const { data } = await addKit(params)
      return data
    },
    addVariantImage({ commit }, { imageData, file, onUploadProgress }) {
      commit('SET_STATE_VALUE', { field: 'loadingAddImage', value: true })
      uploadImage(imageData).then(({ data }) => {
        const signedUrl = data.signed_url
        uploadImageAWS(signedUrl, file, { onUploadProgress }).then(() => {
          commit('SET_NEW_IMAGE', { value: signedUrl.split('?')[0] })
          commit('SET_STATE_VALUE', { field: 'loadingAddImage', value: false })
        })
      })
    },
    async getKitById({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loadingKit', value: true })
      const response = await getKit(id)
      if (isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'kit', value: undefined })
      } else {
        commit('SET_STATE_VALUE', { field: 'kit', value: response.data })
      }
      commit('SET_STATE_VALUE', { field: 'loadingKit', value: false })
    },
    async editKit({ commit }, params) {
      await editKit(params).then(response => {
        if (isError(response.status)) {
          commit('SET_STATE_VALUE', { field: 'kit', value: undefined })
        } else {
          commit('SET_STATE_VALUE', { field: 'kit', value: response.data })
        }
      })
    },
    async editVariantPublished({ commit }, params) {
      let response = false
      try {
        await editVariantPublished(params).then(data => {
          if (isError(data.status)) {
            response = false
          }

          response = true
        })
      } catch (e) {
        response = false
      }
      return response
    },

    async getKitsByExternalId({ commit }, id) {
      const { data } = await getKitsByExternalId(id)

      return data
    },
    resetImages({ commit }) {
      commit('RESET_IMAGES')
    },
    setImages({ commit }, images) {
      images.forEach(image => {
        commit('SET_NEW_IMAGE', { value: image.image })
      })
      // commit('RESET_IMAGES')
    },
    deleteImage({ commit, state }, index) {
      const images = [...state.images]
      images.splice(index, 1)
      commit('RESET_IMAGES')
      images.forEach(image => {
        commit('SET_NEW_IMAGE', { value: image.image })
      })
    },
    setAsMain({ commit, state }, index) {
      const images = [...state.images]
      const element = images[index]
      images.splice(index, 1)
      images.unshift(element)
      commit('RESET_IMAGES')
      images.forEach(image => {
        commit('SET_NEW_IMAGE', { value: image.image })
      })
    },
    getKitFamilies({ commit }, onSuccess) {
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: true })
      getKitFamilies()
        .then(response => {
          commit('SET_KIT_FAMILIES', response.data?.families)
          if (onSuccess) onSuccess()
        })
        .finally(() => commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: false }))
    }
  }
}
