export default {
  active: 'Activas',
  addTechnique: 'Agregar una técnica',
  configTechniques: 'Configurar familias',
  printMinimum: 'Mínimo de impresión',
  families: 'Familia',
  baseColor: 'Color base',
  all: 'Todos',
  area: 'Área',
  btnCancel: 'Cancelar',
  btnSave: 'Guardar',
  code: 'Código',
  laserOnlyMono:'La familia de aplicación Laser solo permite modo mono',
  errors: {
    allFieldsRequired: 'Todos los campos son requeridos',
    code: 'Debe elegir otro código',
    enableError: 'Debe guardar la técnica antes de activarla',
    notNegative: 'El valor debe ser positivo',
    notDecimal: 'El valor debe ser entero',
    underMin: 'El valor no puede ser menor a 0',
    overMax: 'El valor no puede ser mayor a 100',
    required: 'Este campo es requerido'
  },
  family: 'Familia',
  fields: {
    code: 'Código Bejerman',
    family: 'Familia aplicación',
    area: 'Área',
    mode: 'Modo',
    baseTime: 'Tiempo Base',
    ocupationDays: 'Días de ocupación',
    fixedCharge: 'Set up / Cargo fijo',
    unitPrice: 'Unitario',
    name: 'Nombre de impresión',
    daysRatio: 'Coef. un. días',
    discountOverFixedCharge: 'Descuento sobre cargo fijo (%)',
    discountPerUnit: 'Descuento por unidad (%)'
  },
  mode: 'Modo',
  noResultMessage: 'No se encontraron técnicas',
  notActive: 'No Activas',
  published: 'Publicado',
  uploadPrintingTypes: 'Actualización masiva'
}
