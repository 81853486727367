export default {
  addRole: 'Agregar rol',
  permissionsOf: 'Permisos de {role}',
  hasPermision: 'Habilitado',
  save: 'Guardar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  continue: 'Continuar',
  delete: 'Eliminar',
  exit: 'Salir',
  permissions: 'Permisos',
  addRoleTitle: 'Agregar nuevo rol',
  addRoleName: 'Nombre del rol',
  addRoleRequired: 'El nombre es requerido',
  addRoleFailure: 'Ocurrió un error al agregar el nuevo rol',
  cancelEditTitle: '¿Estás seguro de cancelar?',
  cancelEditMessage: 'Quedaron cambios sin guardar',
  editRoleSuccess: 'El rol fue guardado con éxito',
  editRoleFailure: 'Ocurrió un error al guardar el rol',
  deleteRoleTitle: '¿Estás seguro de eliminar este rol?',
  deleteRoleMessage: 'El rol se eliminará por completo',
  deleteRolButton: 'Eliminar',
  deleteRoleSuccess: 'El rol fue eliminado con éxito',
  deleteRoleFailure: 'Ocurrió un error al eliminar el rol',
  deleteRoleFailureAdmin: 'El rol Admin no puede ser eliminado',
  deleteRoleFailureUsers: 'El rol no puede ser eliminado debido a que tiene usuarios asociados',
  withoutRoles: 'Sin roles'
}
