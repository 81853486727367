export default {
  check: 'Transferencia Bancaria / Cheque',
  checking_account: 'Crédito',
  createdAt: 'Creado',
  deliveryMethod: 'Tipo de entrega',
  id: '# de Pedido',
  orderTotalCost: 'Costo total',
  paymentMethod: 'Tipo de pago',
  pending_payment_approval: 'PAP',
  pending_image_approval: 'PAA',
  in_production: 'PROD',
  delivery: 'PRENTRE',
  delivered: 'ENTRE',
  canceled: 'CANC',
  payment_approved: 'PAPROB',
  image_approved: 'IAPROB',
  picking: 'RETIR',
  ready_to_pickup: 'LRETIR',
  pending_delivery: 'PENTRE',
  pending_pickup: 'PENRET',
  pick_up: 'Entrega en sucursal',
  pickup: 'Retirado',
  returned_preparation: 'DEVPRE',
  pending_prepared: 'PENPRE',
  quantity: 'Cantidad',
  shipping: 'Entrega a domicilio',
  status: 'Estado',
  synchronized: 'Sincronizado',
  email: 'Email Partner',
  customized: 'Personalización',
  wire_transfer: 'Transferencia Bancaria / Cheque',
  customize: 'Personalizado',
  uncostomize: 'No personalizado',
  all: 'Todos',
  sync: 'Sincronizado',
  unsync: 'No sincronizado',
  m_simple: 'M. Simple'
}
