import {
  getAdvanceDetails,
  getAdvances,
  getAdvancesStatus,
  sendAdvanceProposal
} from '@/services/AdvancesService'

export default {
  namespaced: true,

  state: {
    advanceDetails: null,
    advances: [],
    currentPage: 1,
    error: null,
    errorCode: null,
    loading: null,
    loadingSendProposal: false,
    advanceStatus: null,
    sort: {},
    success: null,
    totalRows: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_ADVANCES(state, { advances, count, sort }) {
      state.advances = advances
      state.totalRows = count
      state.loading = false
      state.sort = sort
    },
    SET_SUCCESS(state) {
      state.success = true
      state.error = null
      state.errorCode = null
      state.loading = false
    },
    SET_ERROR(state, { error = true, errorCode = null }) {
      state.success = null
      state.error = error
      state.loading = false
      state.errorCode = errorCode
    }
  },

  actions: {
    hydrateAdvanceDetails({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return getAdvanceDetails(id).then(
        response => {
          commit('SET_STATE_VALUE', { field: 'advanceDetails', value: response })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateAdvancesConfig({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return getAdvancesStatus().then(
        response => {
          const { status } = response.data
          const mappedStatus = status.map(elem => ({ label: elem.value, value: elem.id }))
          commit('SET_STATE_VALUE', { field: 'advanceStatus', value: mappedStatus })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateAdvances({ commit }, { page = 1, filters = {}, sort }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters }
      if (sort.by && sort.order) {
        Object.assign(params, { order_column: sort.by, order: sort.order })
      }
      getAdvances(params).then(
        response => {
          const { advances, count } = response.data
          commit('SET_ADVANCES', { advances, count, sort })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    sendAdvanceProposal({ commit, dispatch }, { advanceId, productionDays, price, onSuccess, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loadingSendProposal', value: true })
      sendAdvanceProposal(advanceId, { production_days: productionDays, price })
        .then(
          () => {
            dispatch('hydrateAdvanceDetails', advanceId)
            onSuccess()
          },
          () => onFailure()
        )
        .finally(() => commit('SET_STATE_VALUE', { field: 'loadingSendProposal', value: false }))
    },
    resetError({ commit }) {
      commit('SET_STATE_VALUE', { field: 'error', value: null })
    },
    resetSuccess({ commit }) {
      commit('SET_STATE_VALUE', { field: 'success', value: null })
    }
  }
}
