import { api } from '../config/api'

const serializeHolidaysResponse = response => {
  const { total_count: totalCount, page, total_pages: totalPages, holidays } = response.data
  return {
    data: {
      totalCount,
      page,
      totalPages,
      holidays
    }
  }
}

export const getHolidays = params =>
  api.get('backoffice/holidays', { params }).then(serializeHolidaysResponse)

export const addHoliday = body => api.post('backoffice/holidays', body)

export const deleteHoliday = id => api.delete(`/backoffice/holidays/${id}`)
