export default {
  addUser: 'Crear usuario',
  btnCancel: 'Cancelar',
  btnSave: 'Guardar',
  cancel: 'Salir',
  cancelDescription: '¿Estás seguro de cancelar? Quedaron cambios sin guardar',
  confirm: 'Confirmar contraseña',
  continue: 'Continuar',
  created: 'Creado el',
  creationDate: 'Fecha de creación',
  detail: 'Detalle',
  errors: {
    email: 'Formato incorrecto. Ejemplo: nombre@gmail.com',
    emailExists: 'El email utilizado ya existe',
    required: 'Este campo es obligatorio',
    samePassword: 'Las contraseñas deben ser iguales'
  },
  email: 'Email',
  modificationDate: 'Fecha de modificación',
  modified: 'Modificado el',
  password: 'Contraseña',
  role: 'Rol',
  warning: 'Advertencia'
}
