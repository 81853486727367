import snakeCase from 'lodash.snakecase'
import { uploadImage } from '@/services/ImageService'
import { uploadImageAWS } from '@/services/s3Service'

const fullPercent = 100
const minError = 400
const maxError = 600
const errorCodes = { notFound: 404 }
const CONTENT_TYPE = 'Content-Type'

export const isError = code => code >= minError && code < maxError

export const isError404 = code => code === errorCodes.notFound

export const fieldsToSnakeCase = fields =>
  Object.keys(fields).reduce((acumulator, key) => ({ ...acumulator, [snakeCase(key)]: fields[key] }), {})

export const signAndUploadFile = (upload, path) => {
  const { file } = upload
  const fileNameSplit = file.name.split('.')
  const imageData = {
    extension: fileNameSplit.pop(),
    file_name: fileNameSplit.join(),
    path
  }
  return uploadImage(imageData).then(response => {
    const signedUrl = response.data.signed_url
    const contentType = new URL(signedUrl).searchParams.get(CONTENT_TYPE)
    return uploadImageAWS(signedUrl, file, {
      headers: {
        [CONTENT_TYPE]: contentType
      },
      onUploadProgress: event => {
        const percentCompleted = Math.floor((event.loaded * fullPercent) / event.total)
        upload.onProgress({ percent: percentCompleted })
      }
    }).then(() => signedUrl.split('?')[0])
  })
}
