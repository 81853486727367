import { CamelcaseSerializer } from 'cerealizr'
import { api } from '../config/api'

const attributeSerializer = new CamelcaseSerializer()

const serializeAttributesResponse = response => {
  const { status, statusText, data } = response
  const {
    attributes,
    total_pages: totalPages,
    current_page: currentPage,
    total_count: totalCount,
    count
  } = data
  return {
    status,
    statusText,
    data: {
      count,
      totalCount,
      totalPages,
      currentPage,
      attributes: attributes.map(attribute => attributeSerializer.serialize(attribute))
    }
  }
}

export const getAttributes = ({ page, filters }) =>
  api
    .get('/backoffice/attributes', {
      params: { page, ...filters, limit: 10 }
    })
    .then(serializeAttributesResponse)

export const getAttribute = id => api.get(`backoffice/attributes/${id}`)

export const addAttribute = ({ name, families, subattributes }) =>
  api.post('/backoffice/attributes', { name, families, subattributes })

export const editAttribute = ({ id, name, families, wepod }) =>
  api.put(`/backoffice/attributes/${id}`, { name, families, wepod }).then(attributeSerializer)

export const addSubattribute = ({ id, name }) =>
  api.post(`/backoffice/attributes/${id}/subattributes`, { name })

export const editSubattribute = ({ id, subattributeId, name }) =>
  api.put(`/backoffice/attributes/${id}/subattributes/${subattributeId}`, { name })

export const deleteAttribute = id => api.delete(`/backoffice/attributes/${id}`)

export const deleteSubattribute = ({ id, subattributeId }) =>
  api.delete(`/backoffice/attributes/${id}/subattributes/${subattributeId}`)
