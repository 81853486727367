export default {
  title: 'Descuentos',
  addDiscount: 'Agregar descuento',
  noResultMessage: 'No se encontraron descuentos',
  noResultMessageByQuantity: 'No se encontraron descuentos por cantidad',
  back: 'Volver',
  addFormTitle: 'Nuevo descuento',
  addFormTitleByQuantity: 'Nuevo descuento por cantidad',
  editFormTitle: 'Editar descuento',
  confirm: 'Guardar descuento',
  select: 'Seleccionar',
  byPartnersCategories: 'Por categorías de usuarios',
  byQuantity: 'Por cantidad',
  fields: {
    nameOnly: 'Nombre',
    name: 'Nombre del descuento',
    category: 'Categorías del usuario',
    categories: 'Categorías de usuarios',
    family: 'Familias de producto',
    allPartners: 'Todas',
    allFamilies: 'Todas',
    discount: 'Descuento',
    enabled: 'Habilitado',
    description: 'Descripción',
    discountStartDate: 'Fecha de inicio',
    discountEndDate: 'Fecha de vencimiento',
    discountNoEndDate: 'Sin fecha de vencimiento',
    minQuantity: 'A partir de',
    maxQuantity: 'Hasta',
    withOutLimit: 'Sin límite',
    genericProduct: 'Producto genérico',
    variant: 'Variante',
    color: 'Color / material',
    size: 'Talle',
    active: 'Estado',
    action: 'Habilitar'
  },
  publishedLabel: {
    active: 'Publicado',
    inactive: 'No publicado',
    all: 'Todos'
  },
  editText: 'Editar',
  cancelText: 'Cancelar',
  success: {
    deleteDiscount: 'El descuento fue borrado con exito',
    addDiscount: 'El descuento fue agregado con exito',
    editDiscount: 'El descuento fue guardado con éxito'
  },
  failure: {
    deleteDiscount: 'Hubo un error al borrar el descuento',
    addDiscount: 'Hubo un error al agregar el descuento',
    editDiscount: 'Hubo un error al editar el descuento'
  },
  error: {
    isNameAvailable: 'Ya existe un descuento con ese nombre',
    required: 'Este campo es requerido',
    greater0: 'El porcentaje debe ser mayor que 0'
  }
}
