import {
  getTechniques,
  getTechniquesConfig,
  getTechniquesMinConfig,
  getTechniqueDetails,
  toggleEnableTechnique,
  createTechnique,
  editTechnique,
  editTechniquesConfig
} from '@/services/TechniquesService'

export default {
  namespaced: true,

  state: {
    currentPage: 1,
    enableError: false,
    error: null,
    errorCode: null,
    errors: [],
    families: [],
    loading: false,
    modes: [],
    sort: {},
    success: null,
    techniqueDetails: null,
    techniques: [],
    totalRows: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_TECHNIQUES(state, { techniques, count, sort }) {
      state.techniques = techniques
      state.totalRows = count
      state.loading = false
      state.sort = sort
    },
    SET_SUCCESS(state) {
      state.success = true
      state.error = null
      state.errorCode = null
      state.loading = false
    },
    SET_ERROR(state, { error = true, errorCode = null }) {
      state.success = null
      state.error = error
      state.loading = false
      state.errorCode = errorCode
    }
  },

  actions: {
    hydrateTechniqueDetails({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      getTechniqueDetails(id).then(
        response => {
          commit('SET_STATE_VALUE', { field: 'techniqueDetails', value: response })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateTechniquesConfig({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      return getTechniquesConfig().then(
        response => {
          const { names, modes } = response.data
          const families = names.map(family => ({ label: family.name, value: family.name, id: family.id }))
          commit('SET_STATE_VALUE', { field: 'families', value: families })
          commit('SET_STATE_VALUE', { field: 'modes', value: modes })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateTechniquesConfigMinium({ commit }) {
      return getTechniquesMinConfig().then(
        response => {
          const configFamilies = response.data
          commit('SET_STATE_VALUE', { field: 'familiesConfig', value: configFamilies })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    editTechniquesMinConfig({ commit }, body) {
      return editTechniquesConfig(body).then(
        response => {
          const configFamilies = response
          commit('SET_STATE_VALUE', { field: 'familiesConfig', value: configFamilies })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    hydrateTechniques({ commit }, { page = 1, filters = {}, sort }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters }
      if (sort.by && sort.order) {
        Object.assign(params, { order_column: sort.by, order: sort.order })
      }
      getTechniques(params).then(
        response => {
          const { printingTypes, count } = response.data
          commit('SET_TECHNIQUES', { techniques: printingTypes, count, sort })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        ({ response }) => commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
      )
    },
    async createTechnique({ commit }, body) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', {
        field: 'errors',
        value: []
      })
      await createTechnique(body).then(
        response => {
          commit('SET_SUCCESS')
          commit('SET_STATE_VALUE', { field: 'techniqueDetails', value: response })
        },
        ({ response }) => {
          commit('SET_STATE_VALUE', {
            field: 'errors',
            value: response?.data?.message?.errors ? response.data.message.errors.map(e => e.param) : ['code']
          })
          commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
        }
      )
    },
    editTechnique({ commit }, { id, body }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', {
        field: 'errors',
        value: []
      })
      editTechnique(id, body).then(
        response => {
          commit('SET_SUCCESS')
          commit('SET_STATE_VALUE', { field: 'techniqueDetails', value: response })
          return response
        },
        ({ response }) => {
          commit('SET_STATE_VALUE', {
            field: 'errors',
            value: response?.data?.message?.errors ? response.data.message.errors.map(e => e.param) : ['code']
          })
          commit('SET_ERROR', { error: true, errorCode: response.data.internal_code })
        }
      )
    },
    resetTechniqueDetails({ commit }) {
      commit('SET_STATE_VALUE', {
        field: 'errors',
        value: []
      })
      commit('SET_STATE_VALUE', { field: 'techniqueDetails', value: null })
    },
    toggleEnableTechnique({ commit }, { id, onFailure }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'enableError', value: false })
      toggleEnableTechnique(id).then(
        response => {
          commit('SET_SUCCESS')
          commit('SET_STATE_VALUE', { field: 'techniqueDetails', value: response })
        },
        () => {
          onFailure()
          commit('SET_STATE_VALUE', { field: 'enableError', value: true })
          commit('SET_ERROR')
        }
      )
    },
    resetError({ commit }) {
      commit('SET_STATE_VALUE', { field: 'error', value: null })
    },
    resetSuccess({ commit }) {
      commit('SET_STATE_VALUE', { field: 'success', value: null })
    }
  }
}
