import { api } from '../config/api'

export const getFamilies = () => api.get('/family', { params: { get_all: true, edit: true } })

export const getProductFamilies = () => api.get('/family/products', { params: { get_all: true } })

export const getProductFamiliesWepod = () => api.get('/family/products', { params: { wepod: true } })

export const getFamiliesFlat = () => api.get('/backoffice/family')

export const getFamiliesFilter = () => api.get('/backoffice/family')

export const createFamily = body => api.post('family', body)

export const editFamily = (idFamily, body) => api.put(`/family/${idFamily}`, body)

// TODO: mock response for subfamily list, will update with API
export const getSubfamilies = () => {
  const subfamiliesPromise = new Promise(resolve => {
    const subfamilies = [
      { id: 170, description: 'Imán' },
      { id: 171, description: 'Imán Impreso' },
      { id: 172, description: 'Remera' },
      { id: 173, description: 'Chomba' },
      { id: 174, description: 'Varios' },
      { id: 175, description: 'Camisa' },
      { id: 176, description: 'Buzo' },
      { id: 177, description: 'Chaleco' },
      { id: 178, description: 'Campera' },
      { id: 179, description: 'Impo Especial' },
      { id: 180, description: 'Visera' },
      { id: 181, description: 'Sandwich' },
      { id: 182, description: 'Dry Fyt' },
      { id: 183, description: 'Bufanda' },
      { id: 184, description: 'Gorros' },
      { id: 185, description: 'CAP034' },
      { id: 186, description: 'CAP033' },
      { id: 187, description: 'Toallas' },
      { id: 188, description: 'CAP031' },
      { id: 189, description: 'Chambergo' },
      { id: 190, description: 'CAP001' },
      { id: 191, description: 'CAP004' },
      { id: 192, description: 'CAP020' },
      { id: 193, description: 'Babero' },
      { id: 194, description: 'Gorro Chef' },
      { id: 195, description: 'CAP030' },
      { id: 196, description: 'Maletines' },
      { id: 197, description: 'Bolsos' },
      { id: 198, description: 'Mochilas' },
      { id: 199, description: 'Mini Bolsos' },
      { id: 200, description: 'Premium' },
      { id: 201, description: 'Portfolio' },
      { id: 202, description: 'Nacional' },
      { id: 203, description: '5 Gajos' },
      { id: 204, description: 'Mouse Pad' },
      { id: 205, description: 'Plastico / Metal' },
      { id: 206, description: 'Europeo' },
      { id: 207, description: 'Avíos' },
      { id: 208, description: 'Promocional' },
      { id: 209, description: 'Telas' },
      { id: 210, description: 'Set' },
      { id: 211, description: 'Plastico/metal Europa Bol' },
      { id: 212, description: 'Metal/Goma Asia Bol' },
      { id: 213, description: 'Metal' },
      { id: 214, description: 'Metal Asia Roller' },
      { id: 215, description: 'Europeo' },
      { id: 216, description: 'Metal/Goma Asia Roller' },
      { id: 217, description: 'Calculadora' },
      { id: 218, description: 'Memos' },
      { id: 219, description: 'Aplicaciòn Logo' },
      { id: 220, description: 'Articulos de C amping' },
      { id: 221, description: 'Masivos' },
      { id: 222, description: 'Delantal Cocina' },
      { id: 223, description: 'Banderas' },
      { id: 224, description: 'Agenda Magnetica' },
      { id: 225, description: 'Adhesivado' },
      { id: 226, description: 'Paraguas' },
      { id: 227, description: 'Pins' },
      { id: 228, description: 'Talleristas textiles' },
      { id: 229, description: 'Buttons' },
      { id: 230, description: 'Estuches' },
      { id: 231, description: 'Portacredenciales' },
      { id: 232, description: 'Portatarjetas' },
      { id: 233, description: 'Termos' },
      { id: 234, description: 'Jarro Térmico' },
      { id: 235, description: 'Reloj Pared' },
      { id: 236, description: 'Llaveros' },
      { id: 237, description: 'Tazas Ceramica' },
      { id: 238, description: 'Tijeras' },
      { id: 239, description: 'Calcos' },
      { id: 240, description: 'Reloj de Escritorio' },
      { id: 241, description: 'Reloj Pulsera' },
      { id: 242, description: 'Porta CD' },
      { id: 243, description: 'Antiestress' },
      { id: 244, description: 'Cámara de fotos' },
      { id: 245, description: 'Set viaje' },
      { id: 246, description: 'Portacelular' },
      { id: 247, description: 'Metal varios' },
      { id: 248, description: 'Jarras' },
      { id: 249, description: 'Set de Mate' },
      { id: 250, description: 'Madera/Nac Lapiz' },
      { id: 251, description: 'Empaque' },
      { id: 252, description: 'Camping' },
      { id: 253, description: 'Especiales' },
      { id: 254, description: 'Esponja' },
      { id: 255, description: 'Banderines Golf' },
      { id: 256, description: 'Mouse con Gel' },
      { id: 257, description: 'Camiseta deportiva' },
      { id: 258, description: 'Herramientas' },
      { id: 259, description: 'Linterna' },
      { id: 260, description: 'Librería' },
      { id: 261, description: 'Plasr/Asia/Marcador' },
      { id: 262, description: 'Set de Escr.' },
      { id: 263, description: 'Folia - PVC para Prod.' },
      { id: 264, description: 'Cubiertos de Plàstico' },
      { id: 265, description: 'Metal/Asia/Portaminas' },
      { id: 266, description: 'Plástico/Asia/Pincel' },
      { id: 267, description: 'Musculosa' },
      { id: 268, description: 'Pañuelos' },
      { id: 269, description: 'Accesorios Medicos' },
      { id: 270, description: 'Carteleria' },
      { id: 271, description: 'Parasol' },
      { id: 272, description: 'Delantal' },
      { id: 273, description: 'Capas' },
      { id: 274, description: 'Cajas' },
      { id: 275, description: 'Pelotas de Golf' },
      { id: 276, description: 'Vino' },
      { id: 277, description: 'Radios' },
      { id: 278, description: 'Golf' },
      { id: 279, description: 'Kits' },
      { id: 280, description: 'Ceniceros' },
      { id: 281, description: 'Accesorios de Golf' },
      { id: 282, description: 'Accesorios de Zapatos' },
      { id: 283, description: 'Accesorios Cuidado Pers.' },
      { id: 284, description: 'Individual' },
      { id: 285, description: 'Anteojos' },
      { id: 286, description: 'Kit Escolar' },
      { id: 287, description: 'Encendedor' },
      { id: 288, description: 'Portaretratos' },
      { id: 289, description: 'Jardín' },
      { id: 290, description: 'Billeteras/PortaDoc/PCelu' },
      { id: 291, description: 'Cooler' },
      { id: 292, description: 'Plastico' },
      { id: 293, description: 'Remera TOP' },
      { id: 294, description: 'Remera conf' },
      { id: 295, description: 'CAP035' },
      { id: 296, description: 'CAP036' },
      { id: 297, description: 'CAP041' },
      { id: 298, description: 'Tecnologia' },
      { id: 299, description: 'Muebles' },
      { id: 300, description: 'Accesorios Cerveza' },
      { id: 301, description: 'Lumilite' },
      { id: 302, description: 'Kingtech' },
      { id: 303, description: 'Thermos' },
      { id: 304, description: 'Sunstools' },
      { id: 305, description: 'Tonino Lamborghini' },
      { id: 306, description: 'Dunlop' },
      { id: 307, description: 'Ogio' },
      { id: 308, description: 'DOE' },
      { id: 309, description: 'Insumos' },
      { id: 310, description: 'Bazar' },
      { id: 311, description: 'Benevento' },
      { id: 312, description: 'Pendrives' },
      { id: 313, description: 'Escritorio' },
      { id: 314, description: 'Utiles' },
      { id: 315, description: 'Accesorios' },
      { id: 316, description: 'Algodon' },
      { id: 317, description: 'Jarros' },
      { id: 318, description: 'Morral' },
      { id: 319, description: 'Neceser' },
      { id: 320, description: 'Fútbol' },
      { id: 321, description: 'Producción' },
      { id: 322, description: 'Logistica' },
      { id: 323, description: 'Textil' },
      { id: 324, description: 'Simples' },
      { id: 325, description: '6 Gajos' },
      { id: 326, description: 'Sombrero' },
      { id: 327, description: 'Matera' },
      { id: 328, description: 'Sport Bottle' },
      { id: 329, description: 'Simples' },
      { id: 330, description: 'Juegos' },
      { id: 331, description: 'Cuadernos' },
      { id: 332, description: 'Celular' },
      { id: 333, description: 'Auricular' },
      { id: 334, description: 'Especiales' },
      { id: 335, description: 'Estrategias' },
      { id: 336, description: 'Europeos' },
      { id: 337, description: 'Madera' },
      { id: 338, description: 'Metal/Alta Escritura' },
      { id: 339, description: 'Plástico/Metal' },
      { id: 340, description: 'Asado' },
      { id: 341, description: 'Sillas' },
      { id: 342, description: 'Cantimploras' },
      { id: 343, description: 'Valijas' },
      { id: 344, description: 'Plásticos' },
      { id: 345, description: 'Plumas' },
      { id: 346, description: 'Portaminas' },
      { id: 347, description: 'Rollers' },
      { id: 348, description: 'Set/Juegos' },
      { id: 349, description: 'Estuches' },
      { id: 350, description: 'Refills' },
      { id: 351, description: 'Accesorios' },
      { id: 352, description: 'Memorias' },
      { id: 353, description: 'Mouse' },
      { id: 354, description: 'Mouse Pad' },
      { id: 355, description: 'Porta CD' },
      { id: 356, description: 'Calculadoras Solas' },
      { id: 357, description: 'Reproductores/MP3' },
      { id: 358, description: 'Radios' },
      { id: 359, description: 'Relojes Mesa/Escritorio' },
      { id: 360, description: 'Relojes Pared' },
      { id: 361, description: 'Relojes Personales' },
      { id: 362, description: 'Telefonía' },
      { id: 363, description: 'Fotografía' },
      { id: 364, description: 'Cap 5 Gajos' },
      { id: 365, description: 'Cap 6 Gajos' },
      { id: 366, description: 'Cap 8 Gajos' },
      { id: 367, description: 'Cap 5 Sandwich' },
      { id: 368, description: 'Cap 6 Sandwich' },
      { id: 369, description: 'Cap 8 Sandwich' },
      { id: 370, description: 'Piluso' },
      { id: 371, description: 'Viseras' },
      { id: 372, description: 'Vinchas' },
      { id: 373, description: 'Herramientas/Cortaplumas' },
      { id: 374, description: 'Jarros Térmicos' },
      { id: 375, description: 'Kits cocina/jardín/baño' },
      { id: 376, description: 'Linternas' },
      { id: 377, description: 'Destapadores/Vino' },
      { id: 378, description: 'Tazas/Vasos' },
      { id: 379, description: 'Termos' },
      { id: 380, description: 'Mates/Bombillas' },
      { id: 381, description: 'Lentes/Lupas/Portaanteojo' },
      { id: 382, description: 'Cuchillería' },
      { id: 383, description: 'Ceniceros/Encendedores' },
      { id: 384, description: 'Platos/Bandejas' },
      { id: 385, description: 'Automotor' },
      { id: 386, description: 'Importación Especial' },
      { id: 387, description: 'Puzzles' },
      { id: 388, description: 'Anti Stress' },
      { id: 389, description: 'Ingenio' },
      { id: 390, description: 'Destreza' },
      { id: 391, description: 'Deportes de mesa' },
      { id: 392, description: 'Simples Promocional' },
      { id: 393, description: 'Simples Premium' },
      { id: 394, description: 'Combinados Promocional' },
      { id: 395, description: 'Combinados Premium' },
      { id: 396, description: 'Billeteras/Porta Document' },
      { id: 397, description: 'Carpetas' },
      { id: 398, description: 'Maletines' },
      { id: 399, description: 'Mochilas' },
      { id: 400, description: 'Portfolios' },
      { id: 401, description: 'Riñoñeras' },
      { id: 402, description: 'Bolsos' },
      { id: 403, description: 'Cooler' },
      { id: 404, description: 'Golf/ 1,10 mts.' },
      { id: 405, description: 'Golf/ 1,40 mts.' },
      { id: 406, description: 'Calle Largo' },
      { id: 407, description: 'Calle Corto' },
      { id: 408, description: 'Sombrillas' },
      { id: 409, description: 'Vs Nacionales' },
      { id: 410, description: 'Vs Importacion' },
      { id: 411, description: 'Camping' },
      { id: 412, description: 'Cuadernos' },
      { id: 413, description: 'Textil' },
      { id: 414, description: 'Mercaderías' },
      { id: 415, description: 'Personal Care' },
      { id: 416, description: 'Sombreros' },
      { id: 417, description: 'Valijas' },
      { id: 418, description: 'Botellas' },
      { id: 419, description: 'Parlantes' },
      { id: 420, description: 'Cargadores' },
      { id: 421, description: 'Auriculares' },
      { id: 422, description: 'Uso del Personal' }
    ]
    resolve({ data: subfamilies })
  })
  return subfamiliesPromise
}
