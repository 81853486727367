export default {
  day: 'Día',
  id: 'Id',
  title: 'Días No Laborables',
  nodata: 'nodata',
  addDay: 'Agregar día no laborable',
  addDayConfirm: '¿Está seguro de agregar este día como no laborable?',
  cancelInfoTitle: 'Error',
  cancelInfoMessage: 'Error al eliminar el día no laborable',
  selectDay: 'Agregar un día no laborable',
  datepickerLabel: 'Seleccione un día',
  errorMessage: 'La fecha debe ser un dia de semana y no repetirse',
  errorTitle: 'Error al cargar la fecha'
}
