/* eslint-disable linebreak-style */
import axios from 'axios'

const headers = {
  headers: {
    Accept: 'application/json',
    apiKey: `${process.env.VUE_APP_PICKIT_APIKEY}`,
    token: `${process.env.VUE_APP_PICKIT_TOKEN}`
  }
}

export const putShipping = (body, id) =>
  axios.put(`${process.env.VUE_APP_PICKIT_URL}/transaction/${id}/state`, body, headers)

export const printPickitShipping = id =>
  axios.get(`${process.env.VUE_APP_PICKIT_URL}/transaction/${id}/label`, headers)
