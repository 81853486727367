export default {
  active: 'Activas',
  activeColumn: 'Activa',
  addReserveTitle: 'Agregar Reserva',
  addReserveLabelSku: 'SKU',
  addReserveLabelEmail: 'Email del Partner',
  addReserveLabelQuantity: 'Cantidad a reservar',
  addReserveRequired: 'Requerido',
  addReserveEmail: 'Ingrese un email válido',
  addReserveMinValue: 'Valor mayor o igual a 1',
  addReserveSuccess: 'La reserva fue realizada con éxito',
  addReserveFailure: 'Hubo un error al agregar la reserva',
  addReserveFailurePartner: 'No existe ningún Partner asociado al email {email}',
  addReserveFailureProduct: 'No existe ningún producto asociado al SKU {sku}',
  addReserveFailureStock: 'El producto asociado al SKU {sku}, no tiene stock suficiente',
  addReserveSave: 'Guardar',
  addReserveCancel: 'Cancelar',
  cancelError: 'La reserva no se pudo cancelar',
  cancelSuccess: 'La reserva fue cancelada con éxito',
  cancelInfoTitle: 'Problema al cancelar',
  cancelInfoMessage: 'No es posible cancelar una reserva inactiva',
  createdAt: 'Creación',
  discount: '% Desc.',
  distributor: 'Email Partner',
  errorCancelReservation: 'Ocurrió un error al cancelar la reserva',
  expiredAt: 'Expiración',
  id: '# de Reserva',
  inactive: 'Inactivas',
  noResultMessage: 'No se encontraron reservas',
  title: 'Reservas',
  product: 'Producto',
  quantity: 'Cant.',
  successCancelReservation: 'La reserva fue cancelada con éxito',
  totalPrice: 'Precio total',
  variant: 'Variante'
}
