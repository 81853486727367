import { CamelcaseSerializer } from 'cerealizr'
import { api } from '../config/api'

const catalogSerializer = new CamelcaseSerializer({})

const serializeCatalogsResponse = response => {
  const { status, statusText, data } = response
  const {
    product_catalogs: catalogs,
    total_pages: totalPages,
    current_page: currentPage,
    total_count: totalCount,
    count
  } = data
  return {
    status,
    statusText,
    data: {
      count,
      totalCount,
      totalPages,
      currentPage,
      catalogs: catalogs.map(catalog => catalogSerializer.serialize(catalog))
    }
  }
}

const serializeCatalogResponse = response => {
  const { status, statusText, data } = response
  return {
    status,
    statusText,
    data: {
      catalog: catalogSerializer.serialize(data)
    }
  }
}

export const getCatalogs = ({ page, filters }) =>
  api
    .get('/backoffice/product_catalogs', { params: { page, ...filters, limit: 10 } })
    .then(serializeCatalogsResponse)

export const getAllCatalogsOrder = () => api.get('/backoffice/all_product_catalogs_order')

export const getCatalog = id => api.get(`/backoffice/product_catalog/${id}`).then(serializeCatalogResponse)

export const addCatalog = ({ name, catalogUrl, enabled }) =>
  api
    .post('/backoffice/product_catalog', { name, catalog_url: catalogUrl, enabled })
    .then(serializeCatalogResponse)

export const editCatalog = (id, { name, catalogUrl, enabled, order }) =>
  api
    .put(`/backoffice/product_catalog/${id}`, { name, catalog_url: catalogUrl, enabled, order })
    .then(serializeCatalogResponse)

export const deleteCatalog = id => api.delete(`/backoffice/product_catalog/${id}`)
