/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable camelcase */
import {
  changeStatusOrderExpress,
  cancelOrder,
  deliveryOrder,
  getExpressDistribution,
  getOrderExpressDelivery,
  getOrderInfo,
  getOrders,
  getOrdersExpress,
  getOrdersExpressGroups,
  prepareOrder,
  prepareProduct,
  prepareProductAll,
  synchronizeWithERP
} from '@/services/OrdersService'
import { getFamilies } from '@/services/ProductFamilyService'
import { printShipping } from '@/services/zippin'
import { isError } from '@/utils/api'
import { formatShortDate } from '@/utils/formatters'
import { printPickitShipping, putShipping } from '../../services/pickit'
import { printLlegoShipping } from '../../services/llego'

export default {
  namespaced: true,

  state: {
    loading: false,
    loadingSimple: true,
    loadingOverlay: false,
    currentPage: 1,
    count: 0,
    orders: [],
    sort: {},
    totalRows: null,
    orderDetails: { items: [{}], distributor_user: {}, address: {} },
    error: null,
    erpError: null,
    cancelError: false,
    cancelSuccess: false,
    hasNextPage: false,
    page: 1,
    totalPages: 1,
    families: [],
    headers_preparation_product: [
      {
        value: 'sku',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3', 'rounded-top-left'],
        sortable: true,
        text: 'SKU'
      },
      {
        value: 'name',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Producto',
        width: '22%'
      },
      {
        value: 'families',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Familia',
        width: '20%'
      },
      {
        value: 'quantity',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Cantidad',
        width: '12%'
      },
      {
        value: 'detalle',
        align: 'center',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: false,
        text: 'Detalle',
        width: '20%'
      },
      {
        text: '',
        class: ['px-0'],
        cellClass: [
          'px-1',
          'font-weight-medium',
          'txtColor--text',
          'headerColumn',
          'pt-3',
          'rounded-top-right'
        ],
        value: 'data-table-expand',
        width: '4%'
      }
    ],
    headers_preparation: [
      {
        value: 'id',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'headerColumn', 'txtColor--text', 'pt-3', 'rounded-top-left'],
        sortable: true,
        text: '# de pedido',
        width: '10%'
      },
      {
        value: 'createdAt',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Creada',
        width: '10%'
      },
      {
        value: 'maxPreparationDate',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Max. Preparación',
        width: '16%'
      },
      {
        value: 'email',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'E-Mail Partner',
        width: '22%'
      },
      {
        value: 'quantity',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Cantidad de productos',
        width: '15%'
      },
      {
        value: 'detalle',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: false,
        text: 'Detalle',
        width: '12%'
      },
      {
        text: '',
        class: ['px-0'],
        cellClass: [
          'px-1',
          'font-weight-medium',
          'txtColor--text',
          'headerColumn',
          'pt-3',
          'rounded-top-right'
        ],
        value: 'data-table-expand',
        width: '4%'
      }
    ],
    preparation_product_inner: [
      {
        value: 'sku',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'font-weight-bold', 'py-2'],
        sortable: true,
        text: 'SKU',
        width: '22%'
      },
      {
        value: 'name',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'font-weight-bold', 'py-2'],
        sortable: true,
        text: 'Nombre',
        width: '16%'
      },
      {
        value: 'generalDescription',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Descripción',
        width: '18%'
      },
      {
        value: 'families.description',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Familia',
        width: '16%'
      },
      {
        value: 'quantity',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Cantidad',
        width: '10%'
      },
      {
        value: 'detalle',
        align: 'start',
        class: ['px-1', 'subHeaderColumn'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'py-2'],
        sortable: false,
        text: '',
        width: '18%'
      }
    ],
    preparation_inner: [
      {
        value: 'id',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text'],
        sortable: true,
        text: '# de pedido',
        width: '11%'
      },
      {
        value: 'createdAt',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text'],
        sortable: true,
        text: 'Creada',
        width: '13%'
      },
      {
        value: 'maxPreparationDate',
        align: 'start',
        class: ['px-1', 'font-weight-bold', 'subHeaderColumn'],
        cellClass: ['px-1', 'txtColor--text'],
        sortable: true,
        text: 'Max. Preparación',
        width: '23%'
      },
      {
        value: 'email',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text'],
        sortable: true,
        text: 'E-Mail Partner',
        width: '30%'
      },
      {
        value: 'quantity',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Cantidad',
        width: '10%'
      },
      {
        value: 'detalle',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text'],
        sortable: false,
        text: '',
        width: '13%'
      }
    ],
    headers_shipping: [
      {
        value: 'id',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3', 'rounded-top-left'],
        sortable: true,
        text: '# de pedido',
        width: '10%'
      },
      {
        value: 'deliveryDistributor',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Distribuidor',
        width: '9%'
      },
      {
        value: 'idShipping',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'ID Envío',
        width: '9%'
      },
      {
        value: 'createdAt',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Creado',
        width: '8%'
      },
      {
        value: 'preparationDate',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Preparación',
        width: '11%'
      },
      {
        value: 'deliveryEstimatedDate',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'En. estimada',
        width: '12%'
      },
      {
        value: 'distributor_user',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Partner',
        width: '12%'
      },
      {
        value: 'shipping',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Distribución',
        width: '12%'
      },
      {
        value: 'detalle',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: false,
        text: 'Acciones',
        width: '19%'
      },
      {
        text: '',
        class: ['px-0'],
        cellClass: [
          'px-1',
          'font-weight-medium',
          'txtColor--text',
          'headerColumn',
          'pt-3',
          'rounded-top-right'
        ],
        value: 'data-table-expand',
        width: '4%'
      }
    ],
    headers_pickup: [
      {
        value: 'id',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3', 'rounded-top-left'],
        sortable: true,
        text: '# de pedido',
        width: '13%'
      },
      {
        value: 'createdAt',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Creado',
        width: '10%'
      },
      {
        value: 'preparationDate',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Preparación',
        width: '11%'
      },
      {
        value: 'distributor_user',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-bold', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: true,
        text: 'Partner',
        width: '40%'
      },
      {
        value: 'detalle',
        align: 'start',
        class: ['px-1'],
        cellClass: ['px-1', 'font-weight-medium', 'txtColor--text', 'headerColumn', 'pt-3'],
        sortable: false,
        text: 'Acciones',
        width: '22%'
      },
      {
        text: '',

        class: ['px-0'],
        cellClass: [
          'px-1',
          'font-weight-medium',
          'txtColor--text',
          'headerColumn',
          'pt-3',
          'rounded-top-right'
        ],
        value: 'data-table-expand',
        width: '4%'
      }
    ],
    distribution_inner: [
      {
        value: 'sku',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'font-weight-bold', 'py-2'],
        sortable: true,
        text: 'SKU',
        width: '21%'
      },
      {
        value: 'name',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'font-weight-bold', 'py-2'],
        sortable: true,
        text: 'Nombre',
        width: '20%'
      },
      {
        value: 'generalDescription',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Descripción',
        width: '29%'
      },
      {
        value: 'families.description',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Familia',
        width: '20%'
      },
      {
        value: 'quantity',
        align: 'start',
        class: ['px-1', 'subHeaderColumn', 'font-weight-bold'],
        cellClass: ['px-1', 'txtColor--text', 'py-2'],
        sortable: true,
        text: 'Cantidad',
        width: '10%'
      }
    ]
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_ORDERS(state, { orders, count, sort, hasNextPage, totalPages }) {
      state.orders = orders
      state.totalRows = count
      state.loading = false
      state.sort = sort
      if (hasNextPage) {
        state.hasNextPage = hasNextPage
      }
      if (totalPages) {
        state.totalPages = totalPages
      }
    }
  },

  actions: {
    async getOrders({ commit }, { page = 1, filters = {}, sort }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters, sort, viewType: 'BO' }
      const response = await getOrders(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_ORDERS', { orders: [], count: 0 })
      } else {
        const { orders, count } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_ORDERS', { orders, count, sort })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },

    async getFamilies({ commit }) {
      const response = await getFamilies()
      commit('SET_STATE_VALUE', { field: 'families', value: [] })
      if (!isError(response.status)) {
        const { families } = response.data
        commit('SET_STATE_VALUE', { field: 'families', value: families })
      }
    },

    setLoadingOverlay({ commit }, val) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: val })
    },
    async getOrdersExpress({ commit }, { page = 1, filters = {}, sort }) {
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const params = { page, ...filters, sort }
      const response = await getOrdersExpress(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
        commit('SET_ORDERS', { orders: [], count: 0 })
      } else {
        const { orders, count } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_ORDERS', { orders, count, sort })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },

    async getOrdersExpressGroups(
      { commit },
      { page = 1, filters = {}, sort, limit, groupBy = 'preparation', loadingSimple = true, isReturn = false }
    ) {
      if (!isReturn) {
        commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
        if (loadingSimple) {
          commit('SET_STATE_VALUE', { field: 'loadingSimple', value: true })
        }

        commit('SET_ORDERS', { orders: [], count: 0 })
      }
      const params = { page, groupBy, ...filters, sort, limit }

      const response = await getOrdersExpressGroups(params)
      if (isError(response.status)) {
        if (!isReturn) {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_ORDERS', { orders: [], count: 0 })
        }
      } else {
        const { count, hasNextPage, totalPages } = response.data
        let { orders } = response.data
        const { products } = response.data
        if (groupBy === 'preparation' && orders) {
          orders.forEach(element => {
            let quantity = 0
            if (element.items) {
              element.items.forEach(obj => {
                quantity += obj.quantity
              })
            }
            element.quantity = `${quantity} un.`
          })
          orders = orders.map(obj => ({
            ...obj,
            createdAt: formatShortDate(obj.createdAt),
            maxPreparationDate: formatShortDate(obj.maxPreparationDate)
          }))
        } else if (groupBy === 'preparation_product' && products) {
          products.forEach(element => {
            let quantity = 0
            if (element.items) {
              element.items.forEach(obj => {
                obj.createdAt = formatShortDate(obj.createdAt)
                obj.maxPreparationDate = formatShortDate(obj.maxPreparationDate)
                quantity += obj.quantity
              })
            }

            element.quantity = `${quantity} un.`
          })
          orders = products
        }
        if (isReturn) {
          return { orders, count, sort, hasNextPage, totalPages }
        }
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_ORDERS', { orders, count, sort, hasNextPage, totalPages })
      }
      commit('SET_STATE_VALUE', { field: 'loadingSimple', value: false })
      return true
    },
    async checkOrderPreparation(
      { commit },
      { page = 1, filters = {}, sort, limit = 1, groupBy = 'preparation' }
    ) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      const params = { page, groupBy, ...filters, sort, limit }

      const response = await getOrdersExpressGroups(params)
      const { orders } = response.data
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
      if (orders) {
        return orders[0]
      }
      return response.data.products[0]
    },
    async getOrderExpressDelivery({ commit }, item) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      let orden = { isOrder: false }
      await getOrderExpressDelivery(item)
        .then(response => {
          orden = response.data
        })
        .catch(() => {
          orden = { isOrder: false }
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })
      return orden
    },
    async getExpressDistribution(
      { commit },
      {
        page = 1,
        filters = {},
        sort,
        limit,
        delivery_method = 'shipping',
        loadingSimple = true,
        isReturn = false
      }
    ) {
      if (!isReturn) {
        commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
        commit('SET_ORDERS', { orders: [], count: 0 })
        if (loadingSimple) {
          commit('SET_STATE_VALUE', { field: 'loadingSimple', value: true })
        }
      }
      const params = { page, delivery_method, ...filters, sort, limit }

      const response = await getExpressDistribution(params)
      if (isError(response.status)) {
        if (!isReturn) {
          const { status, statusText } = response
          commit('SET_STATE_VALUE', { field: 'error', value: { status, statusText } })
          commit('SET_ORDERS', { orders: [], count: 0 })
        }
      } else {
        const { count, hasNextPage, totalPages } = response.data
        let { orders } = response.data
        if (orders) {
          orders = orders.map(obj => ({
            ...obj,
            createdAt: formatShortDate(obj.createdAt),
            preparationDate: formatShortDate(obj.preparationDate),
            deliveryEstimatedDate: formatShortDate(obj.deliveryEstimatedDate),
            rawDeliveryEstimatedDate: obj.deliveryEstimatedDate,
            rawPreparationDate: obj.preparationDate
          }))
        }
        if (isReturn) {
          return { orders, count, sort, hasNextPage, totalPages }
        }
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_ORDERS', { orders, count, sort, hasNextPage, totalPages })
      }

      commit('SET_STATE_VALUE', { field: 'loadingSimple', value: false })
      return true
    },
    async getOrderInfo({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'erpError', value: false })
      await getOrderInfo(id).then(
        ({ data }) => commit('SET_STATE_VALUE', { field: 'orderDetails', value: data.order }),
        error => commit('SET_STATE_VALUE', { field: 'error', value: error })
      )
    },
    async prepareProduct({ state, commit }, params) {
      let response = {}

      if (params.id) {
        response = await prepareProduct(params)
      }

      return response
    },
    async prepareProductAll({ state, commit }, params) {
      let response = {}

      if (params.id) {
        response = await prepareProductAll(params)
      }

      return response
    },

    async prepareOrder({ state, commit }, { id }) {
      let response = {}
      if (id) {
        const params = { id }
        response = await prepareOrder(params)
      }

      return response
    },
    async deliveryOrderZipping({ state, commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      let responseZipping = { isDelivered: false }
      await printShipping(id)
        .then(response => {
          responseZipping = response.data
        })
        .catch(() => {
          responseZipping = { isDelivered: false }
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })

      return responseZipping
    },
    async printOrderPickit({ state, commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      let responsePickit = { isDelivered: false }
      await printPickitShipping(id)
        .then(response => {
          responsePickit = response.data
          responsePickit.isDelivered = true
        })
        .catch(() => {
          responsePickit = { isDelivered: false }
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })

      return responsePickit
    },
    async printOrderLlego({ state, commit }, tracking) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      let responseLlego = { isDelivered: false }
      await printLlegoShipping(tracking)
        .then(response => {
          responseLlego = response.data
          responseLlego.isDelivered = true
        })
        .catch(() => {
          responseLlego = { isDelivered: false }
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })

      return responseLlego
    },
    async deliveryOrderPickit({ state, commit }, params) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      let responsePickit = { isDelivered: false }
      await putShipping(params.body, params.id)
        .then(response => {
          responsePickit = response.data
          responsePickit.isDelivered = true
        })
        .catch(() => {
          responsePickit = { isDelivered: false }
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })

      return responsePickit
    },
    async deliveryOrderLlego({ state, commit }, tracking) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      let responseLlego = { isDelivered: false }
      await printLlegoShipping(tracking)
        .then(response => {
          responseLlego = response.data
          responseLlego.isDelivered = true
        })
        .catch(() => {
          responseLlego = { isDelivered: false }
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })

      return responseLlego
    },
    async deliveryOrder({ commit }, params) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      const responseDelivery = await deliveryOrder(params)
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
      return responseDelivery
    },

    synchronizeWithERP({ commit }, id) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      synchronizeWithERP(id).then(
        () => {
          commit('SET_STATE_VALUE', { field: 'erpError', value: false })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        },
        () => {
          commit('SET_STATE_VALUE', { field: 'erpError', value: true })
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        }
      )
    },

    async changeStatusOrderExpress({ commit }, params) {
      commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: true })
      const paramsMap = { status: params.status }
      if (params.productId && params.productId !== '') {
        paramsMap.productId = params.productId
      }
      await changeStatusOrderExpress(params.id, paramsMap)
        .then(() => {
          commit('SET_STATE_VALUE', { field: 'cancelSuccess', value: true })
        })
        .catch(() => {
          commit('SET_STATE_VALUE', { field: 'cancelError', value: true })
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loadingOverlay', value: false })
        })
    },
    cancelOrder({ commit }, params) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      cancelOrder(params)
        .then(() => {
          commit('SET_STATE_VALUE', { field: 'cancelSuccess', value: true })
        })
        .catch(() => {
          commit('SET_STATE_VALUE', { field: 'cancelError', value: true })
        })
        .finally(() => {
          commit('SET_STATE_VALUE', { field: 'loading', value: false })
        })
    },
    resetValue({ commit }, field) {
      commit('SET_STATE_VALUE', { field, value: null })
    }
  }
}
