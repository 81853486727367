/* eslint-disable complexity */
import { CamelcaseSerializer } from 'cerealizr'
import { api } from '@/config/api'
import i18n from '@/config/i18n'

const orderSerializer = new CamelcaseSerializer({
  descriptor: {
    delivery_method: value => i18n.t(`orders.${value}`),
    payment_method: value => i18n.t(`orders.${value}`),
    status: value => i18n.t(`orders.${value}`),
    distributor_user: value => value.email
  }
})

const serializeOrdersResponse = response => {
  const { count, page, total_pages: totalPages, orders } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      orders: orders.map(obj => orderSerializer.serialize(obj))
    }
  }
}

const serializeByOrdersResponse = response => {
  const { count, page, total_pages: totalPages, hasNextPage, orders } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      hasNextPage,
      orders
    }
  }
}

const serializeByProductsResponse = response => {
  const { count, page, total_pages: totalPages, hasNextPage, products } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      hasNextPage,
      products
    }
  }
}

const serializePrepare = response => response.data

export const synchronizeWithERP = id => api.post(`/backoffice/order/${id}/erp_order`)

export const getOrders = ({ sort, ...params }) => {
  const baseUrl = sort && sort.by && sort.order ? `/order?order[${sort.by}]=${sort.order}` : '/order'
  return api.get(baseUrl, { params: { ...params, limit: 100 } }).then(serializeOrdersResponse)
}

export const getOrdersExpress = ({ sort, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order ? `/order_express?order[${sort.by}]=${sort.order}` : '/order_express'
  return api.get(baseUrl, { params: { ...params, limit: 100 } }).then(serializeOrdersResponse)
}

export const getOrdersExpressGroups = ({ sort, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order
      ? `/order_express/${params.groupBy}?order[${sort.by}]=${sort.order}`
      : `/order_express/${params.groupBy}`

  if (!params.external_id || params.external_id === '') {
    delete params.external_id
  } else {
    params.external_id = params.external_id.trim()
  }

  if (!params.email || params.email === '') {
    delete params.email
  } else {
    params.email = params.email.trim()
  }

  if (!params.family_id || params.family_id === '') {
    delete params.family_id
  } else {
    params.family_id = params.family_id.trim()
  }

  if (!params.sku || params.sku === '') {
    delete params.sku
  } else {
    params.sku = params.sku.trim()
  }

  const response = params.groupBy === 'preparation' ? serializeByOrdersResponse : serializeByProductsResponse
  delete params.groupBy
  return api.get(baseUrl, { params: { ...params } }).then(response)
}

export const prepareProduct = ({ ...params }) => {
  const baseUrl = `/order_express/prepare_product/${params.id}`
  return api.put(baseUrl).then(serializePrepare)
}

export const prepareProductAll = ({ ...params }) => {
  const baseUrl = `/order_express/prepare_product/all/${params.id}`
  return api.put(baseUrl).then(serializePrepare)
}
export const prepareOrder = ({ ...params }) => {
  const baseUrl = `/order_express/prepare_order/${params.id}`
  return api.put(baseUrl).then(serializePrepare)
}

export const getExpressDistribution = ({ sort, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order
      ? `/order_express/distribution?order[${sort.by}]=${sort.order}`
      : '/order_express/distribution'

  if (!params.external_id || params.external_id === '') {
    delete params.external_id
  } else {
    params.external_id = params.external_id.trim()
  }
  return api.get(baseUrl, { params: { ...params } }).then(serializeByOrdersResponse)
}

export const changeStatusOrderExpress = (id, params) =>
  api.put(`/order_express/change_status_preparation_order/${id}`, params)

export const getOrderInfo = id => api.get(`/order/${id}`)

export const cancelOrder = params => api.put('/cancel_order', params)

export const deliveryOrder = params =>
  api.put(`/order_express/delivery_order/${params.id}`, params.dataZipping)

export const getOrderExpressDelivery = params => api.get(`/express_deliveries/${params.id}`)

export const getShippingDate = id => api.get(`/express_deliveries/${id}`)

export const sendMailPickup = params =>
  api.get(`/express_deliveries/send_pickup_mail/${params.email}/${params.name}/${params.id}`, params)

export const printTagProduct = ({ ...params }) => {
  const baseUrl = `/order_express/print_tag_product/${params.id}`
  return api({
    method: 'get',
    url: baseUrl,
    responseType: 'blob'
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `tag_product_${params.id}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}

export const printPickitTag = ({ ...params }) => {
  const baseUrl = `/order_express/print_pickit_tag?url=${params.url}`
  return api({
    method: 'get',
    url: baseUrl,
    responseType: 'blob'
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `pickit_tag_${params.pickitCode}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}

export const printLlegoTag = ({ ...params }) => {
  const fileLink = document.createElement('a')

  fileLink.href = params.url
  fileLink.setAttribute('download', `llego_tag_${params.llegoCode}.pdf`)
  document.body.appendChild(fileLink)

  fileLink.click()

  // const baseUrl = `/order_express/print_llego_tag?url=${params.url}`
  // return api({
  //   method: 'get',
  //   url: baseUrl,
  //   responseType: 'blob'
  // }).then(response => {
  //   const fileURL = window.URL.createObjectURL(new Blob([response.data]))
  //   const fileLink = document.createElement('a')
  //   fileLink.href = fileURL
  //   fileLink.setAttribute('download', `llego_tag_${params.llegoCode}.pdf`)
  //   document.body.appendChild(fileLink)
  //   fileLink.click()
  // })
}

export const printTagOrder = ({ ...params }) => {
  const baseUrl = `/order_express/print_tag_order/${params.id}`
  return api({
    method: 'get',
    url: baseUrl,
    responseType: 'blob'
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `tag_order_${params.id}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}
export const printTagTraking = ({ ...params }) => {
  const baseUrl = `/order_express/print_tag_traking/${params.id}`
  return api({
    method: 'get',
    url: baseUrl,
    responseType: 'blob'
  }).then(response => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', `tag_tracking_${params.id}.pdf`)
    document.body.appendChild(fileLink)

    fileLink.click()
  })
}
